import {GET, POST, DELETE, PUT} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getSurveys: (IdWorkspace, pageSize, pagenumber, ColumnName, isAscending) =>
    `${BASE_URL}/api/v1/Survey/SurveyData?IdWorkspace=${IdWorkspace}&pagesize=${pageSize}&pagenumber=${pagenumber}&ColumnName=${ColumnName}&isAscending=${isAscending}`,
  getSurveyTypes: () => `${BASE_URL}/api/v1/Survey/SurveyType`,
  addSurvey: () => `${BASE_URL}/api/v1/Survey`,
  editSurvey: (Id, idSurvey) => `${BASE_URL}/api/v1/Survey?idusergroupsurvey=${Id}&IdSurvey=${idSurvey}`,
  deleteSurvey: () => `${BASE_URL}/api/v1/Survey`,
  getStatusTypes: () => `${BASE_URL}/api/v1/Survey/SurveyStatus`,
  getParticipants: (IdWorkspace, IdUserGroupSurvey, pagenumber, pagesize) =>
    `${BASE_URL}/api/v1/Survey/Participents?IdWorkspace=${IdWorkspace}&IdUserGroupSurvey=${IdUserGroupSurvey}&pagesize=${pagesize}&pagenumber=${pagenumber}`,
};

const SurveyService = {
  getSurveys: async (IdWorkspace, pagenumber, pagesize, ColumnName, isAscending, paramBody) => {
    return POST(urlFactory.getSurveys(IdWorkspace, pagesize, pagenumber, ColumnName, isAscending), paramBody);
  },
  getSurveyTypes: async () => {
    return GET(urlFactory.getSurveyTypes());
  },
  addSurvey: async (data, Id) => {
    return POST(urlFactory.addSurvey(), data);
  },

  editSurvey: async (data, Id, idSurvey) => {
    return PUT(urlFactory.editSurvey(Id, idSurvey), data);
  },
  deleteSurvey: async Id => {
    return DELETE(urlFactory.deleteSurvey(), null, {
      IdUserGroupSurvey: Id,
    });
  },
  getStatusTypes: async () => {
    return GET(urlFactory.getStatusTypes());
  },
  getParticiPant: async (IdWorkspace, IdUserGroupSurvey, pagenumber, pagesize, bodyParams) => {
    return POST(urlFactory.getParticipants(IdWorkspace, IdUserGroupSurvey, pagenumber, pagesize), bodyParams);
  },
};

export default SurveyService;
