import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Box from '@mui/material/Box';
import Loadable from 'react-loadable';

import {Loader} from 'app/shared/components';
import IntroLayout from 'app/Layout/IntroLayout';
import PrivateRoutes from './PrivateRoutes';
import AnonymousRoutes from './AnonymousRoute';
import SSOValidator from 'app/modules/authentication/pages/ssoValidatior/SsoValidator.page';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import OverallprogressDetails from 'app/modules/report/newPages/overallProgress/OverallProgress.page';
import MojoReport from 'app/modules/report/newPages/mojoReport/mojoReport.page';

const Loading = () => <p>Loading ...</p>;

const LoginPage = Loadable({
  loader: () => import('app/modules/authentication/pages/login/Login.page'),
  loading: Loader,
});

const TermsPage = Loadable({
  loader: () => import('app/modules/authentication/pages/terms/Terms.page'),
  loading: Loader,
});

const ConditionPage = Loadable({
  loader: () => import('app/modules/authentication/pages/condition/Condition.page'),
  loading: Loader,
});

const NotFoundPage = Loadable({
  loader: () => import('app/shared/pages/404/NotFound.page'),
  loading: Loader,
});

const DashboardPage = Loadable({
  loader: () => import('app/modules/user/newPages/dashboard/Dashboard.page'),
  loading: Loader,
});

const OpenStixPage = Loadable({
  loader: () => import('app/modules/user/newPages/openStix/OpenStix.page'),
  loading: Loader,
});

const QuestionsPage = Loadable({
  loader: () => import('app/modules/user/newPages/questions/Questions.page'),
  loading: Loader,
});

const ViewReportPage = Loadable({
  loader: () => import('app/modules/report/newPages/viewReport/ViewReport.page'),
  loading: Loader,
});

// const OverallprogressDetails = Loadable({
//   loader: () => import('app/modules/report/newPages/overallProgress/OverallProgress.page'),
//   loading: Loader,
// });

// const MojoReport = Loadable({
//   loader: () => import('app/modules/report/newPages/mojoReport/mojoReport.page'),
//   loading: Loader,
// });

const ManageYardstixPage = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/manageYardstixDesktop/ManageYardstix.page'),
  loading: Loader,
});

const FeedbackPage = Loadable({
  loader: () => import('app/modules/contactAndFeedback/newPages/feedback/Feedback.page'),
  loading: Loader,
});

const ContactPage = Loadable({
  loader: () => import('app/modules/contactAndFeedback/newPages/contact/Contact.page'),
  loading: Loader,
});

const CreateMojoPage = Loadable({
  loader: () => import('app/modules/user/newPages/createMojo/CreateMojo.page'),
  loading: Loader,
});

const AddPartcipantsPage = Loadable({
  loader: () => import('app/modules/user/newPages/createMojo/AddParticipants.page'),
  loading: Loader,
});

const MojoCreatedPage = Loadable({
  loader: () => import('app/modules/user/newPages/mojoCreated/MojoCreated.page'),
  loading: Loader,
});

const AddSurvey = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addOrEditSurvay/AddOrEditSurvey.page'),
  loading: Loader,
});

const CreateWorkSpacePage = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addWorkspace/namePage/AddWorkspaceName.page'),
  loading: Loader,
});

const AddWorkSpaceUserPage = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addWorkspace/usersPage/AddWorkspaceUsers.page'),
  loading: Loader,
});

const WorkSpaceCreated = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addWorkspace/workspaceCreated/WorkspaceCreated.page'),
  loading: Loader,
});

const EditWorkspace = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/EditWorkspace/EditWorkspace.page'),
  loading: Loader,
});
const AddOrEditUser = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addOrEditUser/AddOrEditUser.page'),
  loading: Loader,
});

const AccountSettings = Loadable({
  loader: () => import('app/modules/user/newPages/accountSetings/AccountSettings.page'),
  loading: Loader,
});

const AddOrEditGroups = Loadable({
  loader: () => import('app/modules/user/newPages/manageYardstix/addOrEditGroups/AddOrEditGroups.page'),
  loading: Loader,
});

const SetName = Loadable({
  loader: () => import('app/modules/authentication/pages/setName/SetName.page'),
  loading: Loader,
});

const ResetPassword = Loadable({
  loader: () => import('app/modules/authentication/pages/resetPassword/ResetPassword.page'),
  loading: Loader,
});

const ForgotPassword = Loadable({
  loader: () => import('app/modules/authentication/pages/forgotPassword/forgotPassword.page'),
  loading: Loader,
});

const SetPassword = Loadable({
  loader: () => import('app/modules/authentication/pages/setPassword/SetPassword.page'),
  loading: Loader,
});

const AnonymousValidatorPage = Loadable({
  loader: () => import('app/modules/AnonymousValidator/anonymousValidatior.page'),
  loading: Loader,
});

const TestPage = Loadable({
  loader: () => import('app/modules/user/newPages/AnonymousSurvey/AnonymousQuestion.page'),
  loading: Loader,
});

const AnonymousMojoCompleted = Loadable({
  loader: () => import('app/modules/user/newPages/AnonymousSurvey/AnonymousMojoCompleted.page'),
  loading: Loader,
});

const ShareReportPassword = Loadable({
  loader: () => import('app/modules/user/newPages/shareReport/ShareReportPassword'),
  loading: Loader,
});

const WorkspaceValidator = Loadable({
  loader: () => import('app/modules/WorkspaceValidator/WorkspaceValidator.page'),
  loading: Loader,
});
const BASEPATH = process.env.REACT_APP_BASE_PATH;

const MainRoutes = () => {
  const isMobile = useCheckIsMobile();
  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter basename={BASEPATH}>
        <Box sx={isMobile ? {display: 'flex'} : {display: 'flex', height: '100vh'}}>
          <Routes>
            <Route element={<AnonymousValidatorPage />} path="/mojoinvitation/:param1/:param2/:param3" />
            <Route element={<SSOValidator />} path="/" />
            <Route element={<IntroLayout />}>
              <Route element={<LoginPage />} path="/login/:validId" />
              <Route element={<LoginPage />} path="/login" />
              <Route element={<ContactPage />} path="/contact" />
              <Route element={<TermsPage />} path="/terms" />
              <Route element={<ConditionPage />} path="/condition" />
              <Route element={<CreateWorkSpacePage />} path="/create-first-workspace" />
              <Route element={<SetName />} path="/set-name" />
              <Route element={<ResetPassword />} path="/resetpassword" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route element={<SetPassword />} path="/setpassword" />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route element={<DashboardPage />} path="/user-home" />
              <Route element={<OpenStixPage />} path="/open-stix" />
              <Route element={<QuestionsPage />} path="/questions" />
              <Route element={<ViewReportPage />} path="/reports" />
              <Route element={<OverallprogressDetails />} path="/overallprogressDetails/:idSurvey" />
              <Route element={<MojoReport />} path="/mojoReport/:mojoId" />
              <Route element={<ManageYardstixPage />} path="/manage-yardstix" />
              <Route element={<FeedbackPage />} path="/feedback" />
              <Route element={<CreateMojoPage />} path="/create-mojo" />
              <Route element={<AddPartcipantsPage />} path="/add-participants/:idMojo/:MojoName/" />
              <Route element={<MojoCreatedPage />} path="/mojo-created" />
              <Route element={<AddSurvey />} path="/add-survey" />
              <Route element={<CreateWorkSpacePage />} path="/create-workspace" />
              <Route element={<WorkSpaceCreated />} path="/workspace-created" />
              <Route element={<AddOrEditUser />} path="/add-edit-user" />
              <Route element={<AccountSettings />} path="/account-settings" />
              <Route element={<AddWorkSpaceUserPage />} path="/workspace-users" />
              <Route element={<AddOrEditGroups />} path="/add-edit-groups" />
              <Route element={<EditWorkspace />} path="/edit-workspace" />
            </Route>
            <Route element={<AnonymousRoutes />}>
              <Route element={<TestPage />} path="/anonymous-questions" />
              <Route element={<AnonymousMojoCompleted />} path="/mojo-completed" />
            </Route>

            <Route
              element={<ShareReportPassword />}
              path="/password/:idSurvey/:surveyName/:surveyTypeName/:idSurveyType"
            />
            <Route
              element={<ShareReportPassword />}
              path="/mojo-password/:idSurvey/:surveyName/:surveyTypeName/:GraphIdSurvey"
            />

            <Route
              element={<OverallprogressDetails />}
              path="/report/:idSurvey/:surveyName/:surveyTypeName/:idSurveyType"
            />
            <Route element={<MojoReport />} path="/mojo-report/:idSurvey/:surveyName/:surveyTypeName/:GraphIdSurvey" />

            <Route element={<WorkspaceValidator />} path="/invitation/:validId" />

            <Route element={<NotFoundPage />} path="*" />
          </Routes>
        </Box>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default MainRoutes;
