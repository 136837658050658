import axios from 'axios';
import {getWithExpiry} from 'app/utils/LocalStorage';

const handleResponse = response => {
  try {
    const status = parseInt(response.status, 10);
    if (status === 200 || status === 201) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

const autherizationString = (authRequired = true) => {
  const token = getWithExpiry('token');
  return authRequired ? `Bearer ${token}` : undefined;
};

export const GET = async (url, params = {}, authRequired = true) => {
  return axios
    .get(url, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: autherizationString(authRequired),
      },
    })
    .then(res => {
      return handleResponse(res);
    })
    .catch(error => {
      throw error;
    });
};

export const POST = async (url, body) => {
  return axios
    .post(
      url,
      {...body},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: autherizationString(true),
        },
      },
    )
    .then(res => {
      return handleResponse(res);
    })
    .catch(error => {
      throw error;
    });
};

export const PUT = async (url, body, params) => {
  return axios
    .put(
      url,
      {...body},
      {
        params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: autherizationString(true),
        },
      },
    )
    .then(res => {
      return handleResponse(res);
    })
    .catch(error => {
      throw error;
    });
};

export const DELETE = async (url, data, params) => {
  return axios
    .delete(url, {
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: autherizationString(true),
      },
    })
    .then(res => {
      return handleResponse(res);
    })
    .catch(error => {
      throw error;
    });
};
