import {call, put, takeLatest} from 'redux-saga/effects';
import forgotPasswordService from 'app/api/forgotPassword.service';
import {actions as forgotPasswordActions} from 'app/store/slices/forgotPassword.slice';

function* watchPostPasswordRecovery(action) {
  try {
    yield call(forgotPasswordService.postPaswordRecoveryEmail, action.payload);
    yield put(forgotPasswordActions.postPasswordRecoveryEmailSuccess());
  } catch (error) {
    yield put(forgotPasswordActions.postPasswordRecoveryEmailFailure(error));
  }
}
const forgotPasswordSaga = [takeLatest(forgotPasswordActions.postPasswordRecoveryEmail, watchPostPasswordRecovery)];

export default forgotPasswordSaga;
