import {POST} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  postPaswordRecoveryEmail: username => `${BASE_URL}/api/Accounts/ForgetPassword?username=${username}`,
};
const forgotPasswordService = {
  postPaswordRecoveryEmail: async email => {
    return POST(urlFactory.postPaswordRecoveryEmail(email));
  },
};

export default forgotPasswordService;
