import * as React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {SingleInputDateRangeField} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';

import useStyles from './SingleInputDateRangePicker.styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function SingleInputDateRangePicker({style}) {
  const classes = useStyles();
  return (
    <div style={style}>
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['SingleInputDateRangeField']}>
            <DateRangePicker
              slots={{field: SingleInputDateRangeField}}
              className={classes.picker}
              InputProps={{
                disableUnderline: true,
              }}
              OpenPickerButtonProps={{
                sx: {
                  color: '#000 !important',
                },
              }}
              format="DD-MM-YYYY"
            />
          </DemoContainer>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}
