import React from 'react';
import {styled} from '@mui/system';

const StixCards = ({cardHeading, percentage, previousData}) => {
  return (
    <>
      <ParentContainer>
        <CardHeading>{cardHeading}</CardHeading>
        <PercentageBlock>{`+${percentage}%`}</PercentageBlock>
        <CardFooter>{previousData}</CardFooter>
      </ParentContainer>
    </>
  );
};

export default StixCards;

const ParentContainer = styled('div')({
  width: '210px',
  height: '140px',
  borderRadius: '15px',
  padding: '22px 26px 22px 26px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: '2px solid #EFEFEF',
  flex: 1,
});

const CardHeading = styled('div')({
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '15.8px',
  letterSpacing: '0em',
  //   textAlign: 'left',
  color: '#9A9FA5',
});

const PercentageBlock = styled('div')({
  fontFamily: 'Montserrat',
  fontSize: '32px',
  fontWeight: '700',
  lineHeight: '31px',
  letterSpacing: '0em',
  //   textAlign: 'left',
  color: '#222222',
});

const CardFooter = styled('div')({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '13.64px',
  letterSpacing: '0em',
  //   textAlign: 'left',
  color: '#9A9FA5',
});
