const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(`yardstix-${key}`, JSON.stringify(item));
};

const getWithExpiry = key => {
  const itemStr = localStorage.getItem(`yardstix-${key}`);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(`yardstix-${key}`);
    return null;
  }
  return item.value;
};

const removeItem = key => {
  localStorage.removeItem(`yardstix-${key}`);
};

export {setWithExpiry, getWithExpiry, removeItem};
