import {call, put, takeLatest} from 'redux-saga/effects';

import AuthenticationService from 'app/api/authentication.service';
import {actions as authenticationAction} from 'app/store/slices/authentication.slice';

function* watchHandleGetToken(action) {
  const {redirectUrl, code} = action.payload;
  try {
    const response = yield call(AuthenticationService.getToken, redirectUrl, code);
    yield put(authenticationAction.getTokenSuccess(response));
  } catch (error) {
    yield put(authenticationAction.getTokenFailure(error));
  }
}

function* watchGetUserInfo(action) {
  try {
    const response = yield call(AuthenticationService.getUserInfo);
    const data = {response};
    yield put(authenticationAction.getUserInfoSuccess(data));
  } catch (error) {
    yield put(authenticationAction.getUserInfoFailure(error));
  }
}

function* watchUpdateTnC(action) {
  const {idUser} = action.payload;
  try {
    const response = yield call(AuthenticationService.updateTnC, idUser);
    yield put(authenticationAction.updateTnCSuccess(response));
  } catch (error) {
    yield put(authenticationAction.updateTnCFailure(error));
  }
}

function* watchHandleUserLogin(action) {
  try {
    const response = yield call(AuthenticationService.postUserLogin, action.payload);
    yield put(authenticationAction.handleUserLoginSuccess(response));
    yield put(authenticationAction.getUserInfo({selectedWorkspace: 0}));
  } catch (error) {
    yield put(authenticationAction.handleUserLoginFailure(error));
  }
}

function* watchHandleAnonymousValidation(action) {
  try {
    const response = yield call(AuthenticationService.postAnonymousValidation, action.payload);
    yield put(authenticationAction.handleAnonymousValidationSuccess(response));
  } catch (error) {
    yield put(authenticationAction.handleAnonymousValidationFailure(error));
  }
}

function* watchHandleWorkspaceValidation(action) {
  try {
    const response = yield call(AuthenticationService.postWorkspaceValidation, action.payload);
    yield put(authenticationAction.handleWorkspaceValidationSuccess(response));
  } catch (error) {
    yield put(authenticationAction.handleWorkspaceValidationFailure(error));
  }
}

function* watchHandlePostInvitationAddUser(action) {
  try {
    yield call(AuthenticationService.postInvitationAddUser, action.payload);
    yield put(authenticationAction.postInvitationAddUserSuccess());
  } catch (error) {
    yield put(authenticationAction.postInvitationAddUserError(error));
  }
}
const authenticationSaga = [
  takeLatest(authenticationAction.getToken, watchHandleGetToken),
  takeLatest(authenticationAction.getUserInfo, watchGetUserInfo),
  takeLatest(authenticationAction.updateTnC, watchUpdateTnC),
  takeLatest(authenticationAction.handleUserLogin, watchHandleUserLogin),
  takeLatest(authenticationAction.handleAnonymousValidation, watchHandleAnonymousValidation),
  takeLatest(authenticationAction.handleWorkspaceValidation, watchHandleWorkspaceValidation),
  takeLatest(authenticationAction.postInvitationAddUser, watchHandlePostInvitationAddUser),
];

export default authenticationSaga;
