import {Outlet} from 'react-router-dom';
import {Typography, Box, Grid} from '@mui/material';
import {colors} from 'theme';

const IntroLayout = () => {
  return (
    <Grid container style={{minHeight: '100vh'}}>
      <Grid
        item
        xs={12}
        md={6}
        sm={12}
        className="leftRoot"
        sx={{
          backgroundColor: colors.primary2,
          display: {xs: 'none', md: 'flex'},
          alignItems: 'center',
          paddingRight: '30px',
        }}>
        <Box sx={{marginLeft: '50px'}}>
          <Typography variant="h1" sx={{color: colors.light}}>
            Yardstix
          </Typography>
          <Typography variant="h6" sx={{color: colors.light2, position: 'relative'}}>
            Powered by Nimble Smart Layer
            <span style={{fontSize: '10px', position: 'absolute', top: '-5px'}}>TM</span> &nbsp;&nbsp;&nbsp;
            technologies
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: colors.light,
              textAlign: 'justify !important',
              fontWeight: '400 !important',
              maxWidth: '470px !important',
              marginTop: '25px !important',
            }}>
            Enable your organisation to measure, track and improve the human component in your digital transformation
            program. Gain valuable insights in the current state of your organisation, track progress and improve the
            readiness of your employees for digital transformation!
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sm={12} className="rightRoot">
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default IntroLayout;
