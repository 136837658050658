import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {styled} from '@mui/system';

import ShareModal from 'app/shared/components/shareModal/shareModal';
import {DetailReportHeader, DetailReportContent} from 'app/modules/report/components';
import {actions as ReportActions} from 'app/store/slices/report.slice';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';

const DetailReport = ({handleCloseSharePopup, open}) => {
  const dispatch = useDispatch();
  const isMobile = useCheckIsMobile();
  const {idSurvey} = useParams();

  useEffect(() => {
    if (idSurvey) {
      dispatch(ReportActions.getDetailedReport(idSurvey));
    }
  }, [dispatch, idSurvey]);

  return (
    <MainDiv isMobile={isMobile}>
      <DetailReportHeader />
      <DetailReportContent />
      {open && (
        <ShareModal
          open={open}
          noButtonHanlder={handleCloseSharePopup}
          onClose={handleCloseSharePopup}
          yesButtonHanlder={() => {}}
        />
      )}
    </MainDiv>
  );
};

export default DetailReport;

const MainDiv = styled('div')(isMobile =>
  // isMobile && {
  //   position: 'relative',
  //   height: 'calc(70vh - 120px)',
  //   padding: '16px 16px 0px 16px',
  //   display: 'flex',
  //   flexFlow: 'column',
  // },

  ({
    ...(isMobile && {
      position: 'relative',
      height: 'calc(70vh - 120px)',
      padding: '16px 16px 0px 16px',
      display: 'flex',
      flexFlow: 'column',
    }),
    minHeight: 'calc(70vh - 100px)',
  }),
);
