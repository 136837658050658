import {MenuItems} from 'app/shared/constant';

export const getMenuListAuthorizedForUserRoles = (idRole, hasWorkspace) => {
  if (!hasWorkspace) {
    return MenuItems.filter(item => [3, 4, 5, 6].includes(item.id) && item.isMainMenu);
  }
  if (idRole === null || idRole === 0) {
    const temp = MenuItems.filter(item => item.id !== 3 && item.id !== 2 && item.isMainMenu);
    return temp;
  } else if (idRole === 2) {
    const temp = MenuItems.filter(item => item.id !== 3 && item.isMainMenu);
    return temp;
  } else if (idRole === 3) {
    const temp = MenuItems.filter(item => item.id !== 2 && item.isMainMenu);
    return temp;
  } else {
    const temp = MenuItems.filter(item => item.isMainMenu);
    return temp;
  }
};

export const getMainMenuLabelByRoute = (isMobile, route) => {
  const temp = route.split('/')[1];
  if (temp === 'user-home') {
    return 'Open Stix';
  } else {
    const routeObjByRoute = MenuItems.find(item => item.basePath === temp);
    return routeObjByRoute.label || '';
  }
};
