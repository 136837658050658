import React from 'react';
import {styled} from '@mui/system';
import {Typography, Grid} from '@mui/material';
import {NimbleButton} from 'nimble-design-system';

// import {CommentMojo} from 'app/modules/report/components/index';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import {colors} from 'theme';

// import empty from 'assets/images/mojos//empty.svg';

const MojoDetailReport = ({mojoReports, mojoReportData, idMojo, openCommentBox}) => {
  const isMobile = useCheckIsMobile();

  return (
    <MojoReportDiv isMobile={isMobile}>
      {mojoReports?.map((item, key) => (
        <MainGrid container key={key}>
          <ImgGrid item xs={2}>
            <MojoImgstyle src={item.image} alt="mojoImage" />
          </ImgGrid>
          <SubGrid item xs={9}>
            <SelctedMojoStyle>
              <ContentTypo color={colors.primary2} variant="h4">
                {item.percentage}%
              </ContentTypo>
              <Typography variant="h5" style={{color: colors.dark}}>
                {item.name}
              </Typography>
            </SelctedMojoStyle>
            <BackgroundDesignDiv>
              <BackgroundDesignTypo width={`${item.percentage}%`} color={colors.primary2}></BackgroundDesignTypo>
            </BackgroundDesignDiv>
          </SubGrid>
        </MainGrid>
      ))}

      {mojoReports.length === 0 && (
        <>
          <Typography variant="h5" style={{color: colors.dark}}>
            {' '}
            Nothing to show here
          </Typography>
        </>
      )}

      {/* {mojoReportData[0]?.notAnswered !== 0 && (
        <MainGrid container>
          <ImgGrid item xs={2}>
            <MojoImgstyle src={empty} alt="empty" />
          </ImgGrid>
          <SubGrid item xs={9}>
            <SelctedMojoStyle>
              <ContentTypo color={colors.light7} variant="h4">
                {mojoReportData.length === 0 ? 100 : mojoReportData[0]?.notAnswered}%
              </ContentTypo>
              <Typography variant="h5" style={{color: colors.light7}}>
                Not answered
              </Typography>
            </SelctedMojoStyle>
            <BackgroundDesignDiv>
              <BackgroundDesignTypo color={colors.light7} width={`${mojoReportData[0]?.notAnswered}%`} />
            </BackgroundDesignDiv>
          </SubGrid>
        </MainGrid>
      )} */}

      {/* {isMobile && (
        <div style={{marginTop: '24px', height: '400px'}}>
          <CommentMojo idMojo={idMojo} />
        </div>

      )} */}

      {/* {!isMobile && ( */}
      <div style={{position: 'absolute', bottom: isMobile ? '10%' : '2px', right: '5px'}}>
        {mojoReports.length !== 0 && (
          <NimbleButton
            color={colors.primary2}
            fontWeight="700"
            label="Show comments >"
            onClick={() => {
              // sendEmail();
              openCommentBox();
            }}
            size="small"
            variant="contained"
            fontFamily="Montserrat"
          />
        )}
      </div>
      {/* )} */}
    </MojoReportDiv>
  );
};

export default MojoDetailReport;
const MojoReportDiv = styled('div')(({isMobile}) => ({
  height: '100%',
  overflow: 'auto',
  position: isMobile ? 'static' : 'relative',
}));

const MainGrid = styled(Grid)({
  padding: '24px 0px 0px 0px',
  gap: '24px',
  dispay: 'flex',
  flexFlow: 'row',
});

const ContentTypo = styled(Typography)(({color}) => ({
  color: color,
  textTransform: 'uppercase',
}));

const ImgGrid = styled(Grid)({
  maxWidth: 'unset',
  flexBasis: 'unset',
});

const MojoImgstyle = styled('img')({
  width: '57px',
  height: '57px',
});

const SubGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'column',
  flexBasis: '100%',
  maxWidth: '100%',
});

const SelctedMojoStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '5px',
});

const BackgroundDesignDiv = styled('div')({
  background: colors.light5,
  boxShadow: 'inset 0px 1px 3px rgba(151, 149, 149, 0.08)',
  borderRadius: '29px',
});

const BackgroundDesignTypo = styled(Typography)(({width, color}) => ({
  background: color,
  boxShadow: 'inset 0px 1px 3px rgba(151, 149, 149, 0.08)',
  borderRadius: '29px',
  padding: '6px',
  display: 'flex',
  width: width,
}));
