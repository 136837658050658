import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  projectPhases: [],
  loading: true,
  requestPhaseError: null,
};

const getProjectPhases = state => {
  state.loading = true;
  state.requestPhaseError = null;
};
const getProjectPhasesSuccess = (state, action) => {
  state.projectPhases = action.payload;
  state.loading = false;
  state.requestPhaseError = null;
};

const getProjectPhasesFailure = (state, action) => {
  state.loading = false;
  state.requestPhaseError = action.payload;
};

const projectPhasesSlice = createSlice({
  name: 'projectPhases',
  initialState,
  reducers: {
    getProjectPhases(state) {
      return getProjectPhases(state);
    },
    getProjectPhasesSuccess(state, action) {
      return getProjectPhasesSuccess(state, action);
    },
    getProjectPhasesFailure(state, action) {
      return getProjectPhasesFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = projectPhasesSlice;
