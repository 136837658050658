import {call, put, takeLatest} from 'redux-saga/effects';

import UserService from 'app/api/user.service';
import {actions as userActions} from 'app/store/slices/user.slice';

function* watchGetOpenSurveys(action) {
  const {id, idWorkspaceUser, searchKey} = action.payload;
  try {
    const response = yield call(UserService.getOpenSurveys, id, idWorkspaceUser, searchKey);
    yield put(userActions.getOpenSurveysSuccess(response));
  } catch (error) {
    yield put(userActions.getOpenSurveysFailure(error));
  }
}

function* watchGetQuestionsData(action) {
  const {idQuestion, IdSurveyType, idWorkspaceUser, idSurveyInstance} = action.payload;
  try {
    const response = yield call(UserService.getQuestions, idQuestion, IdSurveyType, idWorkspaceUser, idSurveyInstance);
    yield put(userActions.getQuestionsDataSuccess(response));
  } catch (error) {
    yield put(userActions.getQuestionsDataFailure(error));
  }
}

function* watchAddAnswer(action) {
  const {data, questionCode} = action.payload;
  try {
    const response = yield call(UserService.addAnswer, data, questionCode);
    yield put(userActions.addAnswerSuccess(response));
  } catch (error) {
    yield put(userActions.addAnswerFailure(error));
  }
}

function* watchCreateMojo(action) {
  const {userData, name: SurveyName, idWorkspace, idMojo, isUpdate} = action.payload;
  try {
    const response = yield call(UserService.createMojo, userData, SurveyName, idWorkspace, idMojo, isUpdate);
    yield put(userActions.createMojoSuccess(response));
  } catch (error) {
    yield put(userActions.createMojoFailure(error));
  }
}

function* watchGetMojoInivationLink(action) {
  try {
    const response = yield call(UserService.getMojoInvitation, action.payload);
    yield put(userActions.mojoInvitationSuccess(response));
  } catch (error) {
    yield put(userActions.mojoInvitationFailure(error));
  }
}
function* watchGetUsers(action) {
  const {
    IdWorkspace,
    searchKey,
    page: pagenumber,
    pageSize: pagesize,
    IdStatus,
    isAscending,
    ColumnName,
    Name,
    Email,
    userGroup,
    UserRoles,
  } = action.payload;
  try {
    const response = yield call(
      UserService.getUsers,
      IdWorkspace,
      searchKey,
      pagenumber,
      pagesize,
      IdStatus,
      isAscending,
      ColumnName,
      Name,
      Email,
      userGroup,
      UserRoles,
    );
    const respData = response;
    yield put(userActions.getUsersSuccess({respData, page: pagenumber, searchKey}));
  } catch (error) {
    yield put(userActions.getUsersFailure(error));
  }
}

function* watchAddOrEditUser(action) {
  const {idWorkspace, email, group, UserId, idWorkSpaceUser, prevEmail, idRole, workspaceName} = action.payload;
  try {
    const response = yield call(UserService.validateEmail, email, idWorkspace);
    if (!UserId) {
      if (response && response !== 'Email Not found') {
        yield put(userActions.addOrEditUserFailure({error: 'exist'}));
      } else {
        const groupsData = group.map(item => {
          return {idUserGroup: item.value};
        });
        const addUserResponse = yield call(UserService.addUser, idWorkspace, email, idRole, groupsData, workspaceName);
        yield put(userActions.addOrEditUserSuccess(addUserResponse));
      }
    } else {
      if (response === 'Email Not found' || response === prevEmail) {
        const groupsData = group.map(item => {
          return {
            idWorkspaceUser: idWorkSpaceUser,
            idUserGroup: item.value,
          };
        });
        const editUserResponse = yield call(UserService.editUser, idWorkSpaceUser, email, idRole, groupsData, UserId);
        yield put(userActions.addOrEditUserSuccess(editUserResponse));
      } else {
        yield put(userActions.addOrEditUserFailure({error: 'exist'}));
      }
    }
  } catch (error) {
    yield put(userActions.addOrEditUserFailure(error));
  }
}

function* watchDeleteUser(action) {
  const {idWorkspaceUser} = action.payload;
  try {
    const response = yield call(UserService.deleteUser, idWorkspaceUser);
    yield put(userActions.deleteUserSuccess(response));
  } catch (error) {
    yield put(userActions.deleteUserFailure(error));
  }
}

function* watchReActiveUser(action) {
  const {idWorkspaceUser} = action.payload;
  try {
    const response = yield call(UserService.reactivateUser, idWorkspaceUser);
    yield put(userActions.reactivateUserSuccess(response));
  } catch (error) {
    yield put(userActions.reactivateUserFailure(error));
  }
}

function* watchUserAccountSettings(action) {
  const uploadData = action.payload;
  try {
    const response = yield call(UserService.userAccountSettings, uploadData);
    yield put(userActions.userAccountSettingsSuccess(response));
  } catch (error) {
    yield put(userActions.userAccountSettingsFailure(error));
  }
}

function* watchAddName(action) {
  const {fullName, idUser, idWorkspaceUser} = action.payload;
  try {
    const response = yield call(UserService.addName, fullName, idUser, idWorkspaceUser);
    yield put(userActions.addNameSuccess(response));
  } catch (error) {
    yield put(userActions.addNameFailed(error));
  }
}

function* watchUserRoles(action) {
  try {
    const response = yield call(UserService.getUserRoles);
    yield put(userActions.userRolesSuccess(response));
  } catch (error) {
    yield put(userActions.userRolesFailure(error));
  }
}

function* watchGetInivationLink(action) {
  try {
    const response = yield call(UserService.getInvitation, action.payload);
    yield put(userActions.InvitationSuccess(response));
  } catch (error) {
    yield put(userActions.InvitationFailure(error));
  }
}
const userSaga = [
  takeLatest(userActions.getOpenSurveys, watchGetOpenSurveys),
  takeLatest(userActions.getQuestionsData, watchGetQuestionsData),
  takeLatest(userActions.addAnswer, watchAddAnswer),
  takeLatest(userActions.createMojo, watchCreateMojo),
  takeLatest(userActions.getUsers, watchGetUsers),
  takeLatest(userActions.addOrEditUser, watchAddOrEditUser),
  takeLatest(userActions.deleteUser, watchDeleteUser),
  takeLatest(userActions.reactivateUser, watchReActiveUser),
  takeLatest(userActions.userAccountSettings, watchUserAccountSettings),
  takeLatest(userActions.addName, watchAddName),
  takeLatest(userActions.userRoles, watchUserRoles),
  takeLatest(userActions.mojoInvitation, watchGetMojoInivationLink),
  takeLatest(userActions.Invitation, watchGetInivationLink),
];

export default userSaga;
