import React from 'react';
import Box from '@mui/material/Box';

import useCheckIsMobile from 'app/utils/useCheckIsMobile';

const Pagelayout = ({children, style}) => {
  const isMobile = useCheckIsMobile();

  return (
    <Box
      component="main"
      style={style}
      sx={{
        flexGrow: 1,
        paddingLeft: isMobile ? '25px' : '80px',
        paddingTop: isMobile ? '115px' : '75px',
        paddingBottom: isMobile ? '0px' : '75px',
        paddingRight: isMobile ? '25px' : '80px',
        backgroundColor: '#FCFCFC',
      }}>
      {children}
    </Box>
  );
};

export default Pagelayout;
