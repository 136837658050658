import React from 'react';
import {Dialog, Typography} from '@mui/material';
import {NimbleButton} from 'nimble-design-system';
import {useTranslation} from 'react-i18next';

import {colors} from 'theme';
import {styled} from '@mui/system';

const DialogComponent = ({onClose, open, headerText, label, noButtonHanlder, yesButtonHanlder}) => {
  const {t} = useTranslation();

  return (
    <div>
      <Dailogroot onClose={onClose} open={open}>
        <Typography style={{fontSize: '15px'}}>
          {headerText}&nbsp;
          <LabelTypography variant="h5">{label}</LabelTypography>
        </Typography>
        <ButtonDiv>
          <NimbleButton
            color={colors.primary2}
            fontWeight="700"
            label={t('no')}
            onClick={noButtonHanlder}
            size="large"
            variant="outlined"
            fontFamily="Montserrat"
            fontSize="16px"
          />
          <NimbleButton
            color={colors.dark4}
            fontWeight="700"
            label={t('yes')}
            onClick={yesButtonHanlder}
            size="large"
            variant="contained"
            fontFamily="Montserrat"
            fontSize="16px"
          />
        </ButtonDiv>
      </Dailogroot>
    </div>
  );
};
export default DialogComponent;

const ButtonDiv = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  padding: '16px 0px 0px 0px',
});

const Dailogroot = styled(Dialog)({
  '& .MuiPaper-root': {
    padding: '16px',
  },
});

const LabelTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.primary2,
  fontSize: '14px ',
  lineHeight: '21px !important',
  fontFamily: 'Montserrat !important',
  fontWeight: '400 !important',
});
