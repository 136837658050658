import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  workspaceName: null,
  createWorkSpaceLoading: false,
  createWorkSpaceError: null,

  workspaceEmails: [],

  workspaceData: null,
  workspaceAllData: null,
  mobileWorkspaceData: null,
  getWorkspaceLoading: false,
  getWorkspaceError: null,
  totalPages: 0,

  swapWorkspaceLoading: false,
  swapWorkspaceError: null,

  getWorkspaceNameValidate: null,
  getWorkspaceNameValidateLoading: false,
  getWorkspaceNameValidateError: null,

  selectedWorkspaceForUpdate: null,

  editWorkspaceLoading: false,
  editWorkspaceError: null,

  deleteWorkspaceLoading: false,
  deleteWorkspaceError: null,

  selectedWorkspaceToLeave: null,

  leaveWorkspaceLoading: false,
  leaveWorkspaceError: null,

  deletedWorkSpace: false,
};

const setWorkspaceName = (state, action) => {
  state.workspaceName = action.payload;
};
const createWorkSpace = (state, action) => {
  state.createWorkSpaceLoading = true;
  state.createWorkSpaceError = null;
};
const createWorkSpaceSuccess = (state, action) => {
  state.createWorkSpaceLoading = false;
  state.createWorkSpaceError = null;
};
const createWorkSpaceFailure = (state, action) => {
  state.createWorkSpaceLoading = false;
  state.createWorkSpaceError = action.payload;
};

const getWorkspace = state => {
  state.getWorkspaceLoading = true;
  state.getWorkspaceError = null;
};
const getWorkspaceSuccess = (state, action) => {
  if (action.payload.pagesize > 10) {
    state.workspaceAllData = action.payload.response;
  } else if (action.payload.pagesize === 10 && action.payload.pagenumber === 1) {
    state.mobileWorkspaceData = action.payload?.response?.userWorkSpaces;
    state.totalPages = action?.payload?.response?.totalPage;
  } else if (action.payload.pagesize === 10 && action.payload.pagenumber > 1) {
    state.mobileWorkspaceData = [...state?.mobileWorkspaceData, ...action?.payload?.response?.userWorkSpaces];
    state.totalPages = action?.payload?.response?.totalPage;
  } else if (action.payload.pagesize === 8) {
    state.workspaceData = action.payload.response;
  }

  state.getWorkspaceLoading = false;
  state.getWorkspaceError = null;
};
const getWorkspaceFailure = (state, action) => {
  state.getWorkspaceLoading = false;
  state.getWorkspaceError = action.payload;
};

const swapWorkspace = (state, action) => {
  state.swapWorkspaceLoading = true;
  state.swapWorkspaceError = null;
};
const swapWorkspaceSuccess = (state, action) => {
  state.swapWorkspaceLoading = false;
  state.swapWorkspaceError = null;
};
const swapWorkspaceError = (state, action) => {
  state.swapWorkspaceLoading = false;
  state.swapWorkspaceError = action.payload;
};

const getWorkspaceNameValidateHandler = state => {
  state.getWorkspaceNameValidateLoading = true;
  state.getWorkspaceNameValidateError = null;
};
const getWorkspaceNameValidateSuccses = (state, action) => {
  state.getWorkspaceNameValidateLoading = false;
  state.getWorkspaceNameValidate = action.payload;
  state.getWorkspaceNameValidateError = null;
};
const getWorkspaceNameValidateFailure = (state, action) => {
  state.getWorkspaceNameValidateLoading = false;
  state.getWorkspaceNameValidateError = action.payload;
};

const setSelectedWorkspaceForUpdate = (state, action) => {
  state.selectedWorkspaceForUpdate = action.payload;
};

const EditWorkspace = (state, action) => {
  state.editWorkspaceLoading = true;
};
const EditWorkspaceSuccess = (state, action) => {
  state.editWorkspaceLoading = false;
  state.editWorkspaceError = null;
};
const EditWorkspaceFailure = (state, action) => {
  state.editWorkspaceLoading = false;
  state.editWorkspaceError = action.payload;
};

const DeleteWorkspace = (state, action) => {
  state.deleteWorkspaceLoading = true;
};
const DeleteWorkspaceSuccess = (state, action) => {
  state.deleteWorkspaceLoading = false;
  state.deleteWorkspaceError = null;
};
const DeleteWorkspaceFailure = (state, action) => {
  state.deleteWorkspaceLoading = false;
  state.deleteWorkspaceError = action.payload;
};

const LeaveWorkspace = (state, action) => {
  state.leaveWorkspaceLoading = true;
};
const LeaveWorkspaceSuccess = (state, action) => {
  state.leaveWorkspaceLoading = false;
  state.leaveWorkspaceError = null;
};
const LeaveWorkspaceFailure = (state, action) => {
  state.leaveWorkspaceLoading = false;
  state.leaveWorkspaceError = action.payload;
};

const setDeletedWorkSpace = (state, action) => {
  state.deletedWorkSpace = action.payload;
};

const setSelectedWorkspaceToLeave = (state, action) => {
  state.selectedWorkspaceToLeave = action.payload;
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaceName(state, action) {
      return setWorkspaceName(state, action);
    },
    createWorkSpace(state, action) {
      return createWorkSpace(state, action);
    },
    createWorkSpaceSuccess(state, action) {
      return createWorkSpaceSuccess(state, action);
    },
    createWorkSpaceFailure(state, action) {
      return createWorkSpaceFailure(state, action);
    },
    getWorkspace(state, action) {
      return getWorkspace(state, action);
    },
    getWorkspaceSuccess(state, action) {
      return getWorkspaceSuccess(state, action);
    },
    getWorkspaceFailure(state, action) {
      return getWorkspaceFailure(state, action);
    },
    swapWorkspace(state, action) {
      return swapWorkspace(state, action);
    },
    swapWorkspaceSuccess(state, action) {
      return swapWorkspaceSuccess(state, action);
    },
    swapWorkspaceError(state, action) {
      return swapWorkspaceError(state, action);
    },
    getWorkspaceNameValidateHandler(state, action) {
      return getWorkspaceNameValidateHandler(state, action);
    },
    getWorkspaceNameValidateSuccses(state, action) {
      return getWorkspaceNameValidateSuccses(state, action);
    },
    getWorkspaceNameValidateFailure(state, action) {
      return getWorkspaceNameValidateFailure(state, action);
    },
    setSelectedWorkspaceForUpdate(state, action) {
      return setSelectedWorkspaceForUpdate(state, action);
    },

    EditWorkspace(state, action) {
      return EditWorkspace(state, action);
    },
    EditWorkspaceSuccess(state, action) {
      return EditWorkspaceSuccess(state, action);
    },
    EditWorkspaceFailure(state, action) {
      return EditWorkspaceFailure(state, action);
    },

    DeleteWorkspace(state, action) {
      return DeleteWorkspace(state, action);
    },
    DeleteWorkspaceSuccess(state, action) {
      return DeleteWorkspaceSuccess(state, action);
    },
    DeleteWorkspaceFailure(state, action) {
      return DeleteWorkspaceFailure(state, action);
    },

    LeaveWorkspace(state, action) {
      return LeaveWorkspace(state, action);
    },
    LeaveWorkspaceSuccess(state, action) {
      return LeaveWorkspaceSuccess(state, action);
    },
    LeaveWorkspaceFailure(state, action) {
      return LeaveWorkspaceFailure(state, action);
    },
    setDeletedWorkSpace(state, action) {
      return setDeletedWorkSpace(state, action);
    },

    setSelectedWorkspaceToLeave(state, action) {
      return setSelectedWorkspaceToLeave(state, action);
    },
  },
});

export const {name, actions, reducer} = workspaceSlice;
