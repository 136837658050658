import {Outlet, Navigate} from 'react-router-dom';

import MainLayout from 'app/Layout/MainLayout';
import {getWithExpiry} from 'app/utils/LocalStorage';

const PrivateRoutes = () => {
  const token = getWithExpiry('token');
  const isSignedIn = !!token;
  return isSignedIn ? (
    <>
      <MainLayout />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoutes;
