import moment from 'moment';

export const isValidEmail = email => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isValidPassword = password => {
  // return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^])(?=.*[a-zA-Z]).{8,}$/.test(password);
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^])(?=.*[a-zA-Z])[@#$%^a-zA-Z\d]{8,}$/.test(password);
};

export const isValidName = name => {
  return /^[A-Za-z\s]*$/.test(name);
};
export const isValidSetName = name => {
  return /^[A-Za-z\s\-_.,!@#$%^&*()+=?<>;:'"{}|~]*$/.test(name);
};

export const changeFormatOfDate = date => {
  return moment.utc(date).format('DD-MM-YYYY');
};
