import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 8,
    },
    textFieldLabel: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 600,
      color: '#0C1B2A',
      marginBottom: 4,
    },
    textFieldBase: {
      fontFamily: 'Montserrat !important',
      fontSize: '16px !important',
      fontWeight: '400 !important',
      color: '#0C1B2A !important',
      width: '100%',
      borderRadius: 5,
      padding: '4px 8px',
      background: '#fff',
    },
    textField: {
      borderRadius: '5px',
      border: '1px solid var(--grey-500, #E0E3EB)',
      background: 'rgba(255, 255, 255, 0.40)',
      boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
      height: '37px',
      '&:hover': {
        border: '1px solid var(--grey-500, #E0E3EB)',
        boxShadow: '0px 3px 5px rgba(39, 47, 53, 0.08)',
      },
      '&:focus-within': {
        border: 'none',
        boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED',
      },
    },
    textFieldDisabled: {
      border: '1px solid #E0E3EB',
      boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
      backgroundColor: '#f0eeee',
    },
    textFieldError: {
      boxShadow: '0px 0px 0px 2px #FAD4CC, inset 0px 0px 0px 1px #EC4C29',
      '&:hover': {
        boxShadow: '0px 0px 0px 2px #FAD4CC, inset 0px 0px 0px 1px #EC4C29',
      },
    },
    errorWrapper: {
      display: 'flex',
      gap: '10px',
      alignItems: 'flex-start',
      marginTop: '9px',
    },
    errorMessage: {
      fontWeight: '400',
      fontSize: 12,
      fontFamily: 'Montserrat',
      color: '#EC4C29',
      lineHeight: '16px',
    },
  }),
);

export default useStyles;
