import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    label: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 600,
      color: '#0C1B2A',
      margin: '4px 0px 4px 0px',
    },
    picker: {
      fontWeight: '400 !important',
      color: '#0C1B2A !important',
      border: '1px solid var(--grey-500, #E0E3EB) !important',
      width: '100%',
      // boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
      borderRadius: 5,
      '&:hover': {
        border: '1px solid var(--grey-500, #E0E3EB) !important',
        boxShadow: '0px 0px 0px 2px #dae3f0, 0px 0px 0px 1px #9A9FA5 inset !important',
      },
      '&:focus-within': {
        border: 'none !important',
        boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED !important',
      },
      '& .MuiInputBase-root': {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#0C1B2A !important',
        padding: '0px 6px',
      },
      '& .MuiInputAdornment-root': {
        color: '#000 !important',
      },
      '& .MuiButtonBase-root': {
        color: '#000 !important',
      },
      '& .Mui-disabled': {
        color: 'grey !important',
      },
      '& fieldset': {border: 'none'},
    },
    pickerError: {
      fontFamily: 'Montserrat !important',
      fontSize: '16px !important',
      fontWeight: 400,
      color: '#0C1B2A',
      width: '90%',
      padding: '5px 7px !important',
      boxShadow: '0px 0px 0px 2px #FAD4CC, inset 0px 0px 0px 1px #EC4C29',
      borderRadius: 5,
      background: '#fafafa !important',
      '& .MuiInputBase-root': {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#0C1B2A !important',
        padding: '0px 6px',
      },
      '& .MuiButtonBase-root': {
        color: '#000 !important',
      },
      '& fieldset': {border: 'none'},
    },
    errorWrapper: {
      display: 'flex',
      gap: '10px',
      marginTop: 5,
    },
    errorMessage: {
      fontWeight: '400',
      fontSize: 12,
      fontFamily: 'Montserrat',
      color: '#EC4C29',
      lineHeight: '16px',
    },
  }),
);

export default useStyles;
