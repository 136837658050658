import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    picker: {
      fontWeight: '400 !important',
      color: '#0C1B2A !important',
      border: '1px solid #9A9FA5 !important',
      width: '100%',
      boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
      borderRadius: 5,
      '&:hover': {
        border: '1px solid #9A9FA5 !important',
        boxShadow: '0px 3px 5px rgba(39, 47, 53, 0.08)',
      },
      '&:focus-within': {
        border: 'none !important',
        boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED',
      },
      '& .MuiInputBase-root': {
        color: '#0C1B2A !important',
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: 400,
      },
    },
  }),
);

export default useStyles;
