import {call, put, takeLatest} from 'redux-saga/effects';

import GeneralService from 'app/api/general.service';
import {actions as generalActions} from 'app/store/slices/general.slice';

function* watchGetAllUsers(action) {
  try {
    const response = yield call(GeneralService.getAllUsers);
    yield put(generalActions.getAllusersSuccess(response));
  } catch (error) {
    yield put(generalActions.getAllusersFailure(error));
  }
}

const generalSaga = [takeLatest(generalActions.getAllusers, watchGetAllUsers)];

export default generalSaga;
