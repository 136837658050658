import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {styled} from '@mui/system';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {NimbleSelect} from 'nimble-design-system';

import {actions as ReportActions} from 'app/store/slices/report.slice';
import {commonStyleProps} from 'app/shared/constant';
import {RandomColors} from 'app/utils/RandomColors';

const MojoGraph = ({HideFilter, IdSurveyType, IdSurvey}) => {
  const dispatch = useDispatch();

  const [selectedDaysInverval, setSelectedDaysInverval] = useState(1);
  const [selectedDesignation, setSelectedDesignation] = useState(1);

  const [data, setData] = useState(null);
  const [designationNames, setdesignationNames] = useState(null);
  const [designationColors, setDesignationColors] = useState({});

  const [daysList, setDaysList] = useState(null);
  const [designationList, setDesignationList] = useState(null);

  const MojoGraphData = useSelector(state => state.report.mojoGraphData);
  const DropDownList = useSelector(state => state.report.reportGraphDropDownList);
  const SurveyCategoryList = useSelector(state => state.report.reportSurveyCategoryDropDownList);

  useEffect(() => {
    dispatch(ReportActions.getReportGraphDropDown(IdSurveyType));
    dispatch(ReportActions.getReportSurveyCategoryDropDown());
  }, []);

  useEffect(() => {
    // if (MojoGraphData) {
    //   const data = Object.values(
    //     MojoGraphData?.reduce((acc, curr) => {
    //       const {datesOrMonths, designation, mojoPercentage} = curr;
    //       if (!acc[datesOrMonths]) {
    //         acc[datesOrMonths] = {datesOrMonths, designation: {}};
    //       }
    //       acc[datesOrMonths].designation[designation] = mojoPercentage;
    //       return acc;
    //     }, {}),
    //   );
    //   const designationNames = Object.keys(data[0].designation);
    //   setData(data);
    //   setdesignationNames(designationNames);
    //   const designationColors = RandomColors(designationNames);
    //   setDesignationColors(designationColors);
    //   // setDesignationColors({
    //   //   Manager: '#8884d8',
    //   //   Medewerker: '#82ca9d',
    //   //   'Directeur/MT': '#C00707',
    //   // });
    // }

    if (MojoGraphData) {
      const data = Object.values(
        MojoGraphData?.reduce((acc, curr) => {
          const {datesOrMonths, xvalue, mojoPercentage} = curr;
          if (!acc[datesOrMonths]) {
            acc[datesOrMonths] = {datesOrMonths, xvalue: {}};
          }
          acc[datesOrMonths].xvalue[xvalue] = mojoPercentage;
          return acc;
        }, {}),
      );
      const designationNames = Object.keys(data[0].xvalue);

      setData(data);
      setdesignationNames(designationNames);
      const designationColors = RandomColors(designationNames);
      setDesignationColors(designationColors);
    }
  }, [MojoGraphData]);

  useEffect(() => {
    setDaysList(DropDownList?.interval_ChangeRedinesses);
    setDesignationList(SurveyCategoryList);
  }, [DropDownList]);

  useEffect(() => {
    dispatch(
      ReportActions.getMojoGraph({
        IdSurvey: IdSurvey,
        IntervalType: selectedDaysInverval,
        IdDesignation: selectedDesignation,
      }),
    );
  }, [selectedDaysInverval, selectedDesignation]);

  const handleDaysChange = value => {
    setSelectedDaysInverval(value);
  };

  const handleDesignationChange = value => {
    if (value === '') {
      setSelectedDesignation(0);
    } else {
      setSelectedDesignation(value);
    }
  };

  const formatYAxisTick = value => `${value}%`;

  return (
    <>
      <ParentContainer>
        {!HideFilter && (
          <FilterContainer>
            <NimbleSelect
              {...commonStyleProps}
              data={designationList}
              value={selectedDesignation}
              onBlur={function noRefCheck() {}}
              onChange={handleDesignationChange}
              placeholder=""
              width={'150px'}
              fontFamily="Montserrat"
              isEnableClear={false}
              defaultValue={1}
            />
            <NimbleSelect
              {...commonStyleProps}
              data={daysList}
              value={selectedDaysInverval}
              onBlur={function noRefCheck() {}}
              onChange={handleDaysChange}
              placeholder=""
              width={'150px'}
              defaultValue={1}
              isEnableClear={false}
              fontFamily="Montserrat"
            />
          </FilterContainer>
        )}

        <ResponsiveContainer width="98%" height={400}>
          <LineChart data={data} margin={{top: 30, right: 30, left: 20, bottom: 5}}>
            <CartesianGrid strokeDasharray="3 0" stroke="#EFEFEF" vertical={false} />
            <XAxis dataKey="datesOrMonths" padding={{left: 20, right: 20}} />
            <YAxis tickFormatter={formatYAxisTick} ticks={[0, 20, 40, 60, 80, 100]} />
            <Tooltip />
            <Legend />
            {designationNames &&
              designationNames.map((key, index) => (
                <Line key={index} type="linear" name={key} dataKey={`xvalue.${key}`} stroke={designationColors[key]} />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </ParentContainer>
    </>
  );
};

export default MojoGraph;

const ParentContainer = styled('div')({
  position: 'relative',
  width: '100%',
  // paddingTop: '80px',
  paddingTop: '20px',
  borderRadius: '10px',
  boxShadow: '2px 2px 15px #EFEFEF',
});

const FilterContainer = styled('div')({
  display: 'flex',
  gap: '10px',

  // position: 'absolute',
  // right: '20px',
  // top: '10px',

  justifyContent: 'flex-end',
  width: '100%',
  paddingRight: '20px',
});
