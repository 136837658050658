import WorkspaceService from 'app/api/workspace.service';
import {call, put, takeLatest} from 'redux-saga/effects';

import {actions as projectPhasesActions} from 'app/store/slices/projectPhases.slice';

function* watchGetProjectPhases() {
  try {
    const response = yield call(WorkspaceService.getProjectPhases);
    yield put(projectPhasesActions.getProjectPhasesSuccess(response));
  } catch (error) {
    yield put(projectPhasesActions.getProjectPhasesFailure(error));
  }
}

const projectPhasesSagas = [takeLatest(projectPhasesActions.getProjectPhases.type, watchGetProjectPhases)];

export default projectPhasesSagas;
