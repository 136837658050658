import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 5,
      // background: '#fff',
      width: '100%',
      height: '37px',
      '& .MuiOutlinedInput-root': {
        padding: '0px 9px !important',
        fontFamily: 'Montserrat !important',
      },
      '& .MuiAutocomplete-input': {
        fontFamily: 'Montserrat',
      },
    },

    errorRoot: {
      width: '100%',
      border: '1px solid #EC4C29 !important',
      boxShadow: '0px 0px 0px 2px #FAD4CC, inset 0px 0px 0px 1px #EC4C29 !important',
      marginBottom: '10px',
      borderRadius: 5,
      '& .MuiOutlinedInput-root': {
        padding: '0px 9px !important',
        fontFamily: 'Montserrat !important',
        background: '#fafafa !important',
      },
      '& .MuiAutocomplete-input': {
        fontFamily: 'Montserrat',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'unset !important',
        borderWidth: '0 !important',
      },
    },
    textFieldLabel: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 600,
      color: '#0C1B2A',
      marginBottom: 4,
      marginTop: 7,
    },
    errorWrapper: {
      display: 'flex',
      gap: '10px',
    },
    errorMessage: {
      fontWeight: '400',
      fontSize: '12px !important',
      fontFamily: 'Montserrat !important',
      color: '#EC4C29',
      lineHeight: '16px',
    },
  }),
);

export default useStyles;
