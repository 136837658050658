import {call, put, takeLatest} from 'redux-saga/effects';

import ContactAndFeedbackService from 'app/api/contactAndFeedback.service';
import {actions as contactAndFeedbackActions} from 'app/store/slices/contactAndFeedback.slice';

function* watchSendFeedback(action) {
  const {comment, name, email} = action.payload;
  try {
    const response = yield call(ContactAndFeedbackService.sendFeedback, comment, name, email);
    yield put(contactAndFeedbackActions.sendFeedbackuccess(response));
  } catch (error) {
    yield put(contactAndFeedbackActions.sendFeedbackFailure(error));
  }
}

function* watchSendContact(action) {
  const {email, company, message} = action.payload;
  try {
    const response = yield call(ContactAndFeedbackService.sendContact, email, company, message);
    yield put(contactAndFeedbackActions.sendContactSuccess(response));
  } catch (error) {
    yield put(contactAndFeedbackActions.sendContactFailure(error));
  }
}

const contactAndFeedbackSaga = [
  takeLatest(contactAndFeedbackActions.sendFeedback, watchSendFeedback),
  takeLatest(contactAndFeedbackActions.sendContact, watchSendContact),
];

export default contactAndFeedbackSaga;
