import {saveAs} from 'file-saver';
export default function base64ToExcelFile(base64Data, fileName) {
  const binaryData = atob(base64Data);

  const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  saveAs(blob, fileName);
}
