import React from 'react';
import {Typography} from '@mui/material';
import {styled} from '@mui/system';

import {colors} from 'theme';

const BottomNavigation = ({tabData}) => {
  return (
    <FooterMainDiv>
      {tabData.map(({action, key, icon, iconStyle, text}, index) =>
        key !== 'fill-form' ? (
          <IconWrapper iconStyle={iconStyle} onClick={action} key={index}>
            {text ? (
              <Typography variant="h3" color={colors.primary2}>
                {text}
              </Typography>
            ) : (
              <img src={icon} alt={key} />
            )}
          </IconWrapper>
        ) : (
          <FillFormWrapper key={index}>
            <ContentColor variant="h6" color={colors.light7}>
              Fill out form to save
            </ContentColor>
          </FillFormWrapper>
        ),
      )}
    </FooterMainDiv>
  );
};

export default BottomNavigation;

const FooterMainDiv = styled('div')({
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  height: '77px',
  width: '100%',
  boxShadow:
    'rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px',
});

const IconWrapper = styled('div')(({iconStyle}) => ({
  ...iconStyle,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: '#F1F1F1',
}));

const FillFormWrapper = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
});

const ContentColor = styled(Typography)(color => ({
  color: color,
}));
