import {useRef, useEffect} from 'react';

export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useApiResponseHandler = (flag, error, callBackFun, errorCallback) => {
  const prevFlag = usePrevious(flag);
  useEffect(() => {
    if (prevFlag && !flag) {
      if (!error) {
        callBackFun();
      } else {
        if (errorCallback) {
          errorCallback();
        }
      }
    }
  }, [flag, error, callBackFun, prevFlag, errorCallback]);
};
