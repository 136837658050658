import {GET} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getAllUsers: () => `${BASE_URL}/api/v1/user/allUsers`,
};

const GeneralService = {
  getAllUsers: async () => {
    return GET(urlFactory.getAllUsers(), null);
  },
};

export default GeneralService;
