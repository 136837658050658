import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: 5,
    },
    textFieldLabel: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 600,
      color: '#0C1B2A',
      marginBottom: 8,
    },
    selectField: {
      fontFamily: 'Montserrat !important',
      fontSize: '16px !important',
      fontWeight: 400,
      color: '#0C1B2A',
      border: '1px solid #E0E3EB',
      width: '100%',
      boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
      padding: 'unset !important',
      height: 38,
      '&:control': {
        overFlow: 'scroll !important',
        maxHeight: '100px',
      },
      '&:hover': {
        boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED',
      },
    },
    errorWrapper: {
      display: 'flex',
      gap: '10px',
    },
    errorMessage: {
      fontWeight: '400',
      fontSize: 12,
      fontFamily: 'Montserrat',
      color: '#EC4C29',
      lineHeight: '16px',
    },
  }),
);

export default useStyles;
