import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  postResetPasswordLoading: false,
  postResetPasswordError: null,

  postSetPasswordLoading: false,
  postSetPasswordError: null,
};

const ResetPasswordSlice = createSlice({
  name: '@@resetPassword',
  initialState,
  reducers: {
    postResetPassword(state, action) {
      state.postResetPasswordLoading = true;
    },
    postResetPasswordSuccess(state) {
      state.postResetPasswordLoading = false;
    },
    postResetPasswordFailure(state, action) {
      state.postResetPasswordLoading = false;
      state.postResetPasswordError = action.payload;
    },

    postSetPassword(state, action) {
      state.postSetPasswordLoading = true;
    },
    postSetPasswordSuccess(state) {
      state.postSetPasswordLoading = false;
    },
    postSetPasswordFailure(state, action) {
      state.postSetPasswordLoading = false;
      state.postSetPasswordError = action.payload;
    },
  },
});

export const {name, actions, reducer} = ResetPasswordSlice;
