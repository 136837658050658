import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const generatePdfFromElement = (elementSelector, surveyName, SurveyReport, changeFormatOfDate) => {
  const capture = document.querySelector(elementSelector);

  html2canvas(capture).then(canvas => {
    const imgData = canvas.toDataURL('.img/png');
    const doc = new jsPDF('l', 'mm', [canvas.width, canvas.height]);

    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();

    const canvasAspectRatio = canvas.width / canvas.height;
    const imageWidth = pdfWidth - 40;
    const imageHeight = imageWidth / canvasAspectRatio;

    const paddingX = (pdfWidth - imageWidth) / 2;
    const paddingY = (pdfHeight - imageHeight) / 2;

    doc.addImage(imgData, 'PNG', paddingX, paddingY, imageWidth, imageHeight);

    doc.save(
      `${surveyName || SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.pdf`,
    );
  });
};

export const generateStixPdfFromElement = (elementSelector, surveyName, SurveyReport, changeFormatOfDate) => {
  const capture = document.querySelector(elementSelector);

  const {clientHeight, scrollHeight} = capture;
  const canvasHeight = Math.max(clientHeight, scrollHeight) + 20;

  html2canvas(capture, {height: canvasHeight}).then(canvas => {
    const imgData = canvas.toDataURL('.img/png');
    const doc = new jsPDF('l', 'mm');

    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();

    const canvasAspectRatio = canvas.width / canvas.height;
    const imageWidth = pdfWidth - 40;
    const imageHeight = imageWidth / canvasAspectRatio;

    const paddingX = (pdfWidth - imageWidth) / 2;
    const paddingY = (pdfHeight - imageHeight) / 2;

    doc.addImage(imgData, 'PNG', paddingX, paddingY, imageWidth, imageHeight);

    doc.save(
      `${surveyName || SurveyReport.surveyName} ${changeFormatOfDate(new Date().toISOString().split('T')[0])}.pdf`,
    );
  });
};
