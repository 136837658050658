import {GET, POST} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getOverallReport: (Idsurvey, IdUserGrpSurvey) =>
    `${BASE_URL}/SummaryReport?Idsurvey=${Idsurvey}&IdUserGrpSurvey=${IdUserGrpSurvey}`,
  getDetailedReport: () => `${BASE_URL}/TopicReport`,
  getSurveyReport: (searchData, startDate, endDate, workspaceId, sortNewToOld) =>
    `${BASE_URL}/SurveyReports?searchKey=${searchData}&StartDate=${startDate}&EndDate=${endDate}&workspaceId=${workspaceId}&SortNewToOld=${sortNewToOld}`,
  getMojoReport: () => `${BASE_URL}/MojoReport`,
  getMojoComments: () => `${BASE_URL}/MojoComments`,
  getSurveyExcelFile: IdSurvey => `${BASE_URL}/StixExcel?IdSurvey=${IdSurvey}`,
  getMojoExcelFile: IdSurvey => `${BASE_URL}/MojoExcel?IdSurvey=${IdSurvey}`,
  shareReport: () => `${BASE_URL}/ShareReport`,
  reportPasswordValidation: (IdSurvey, Password) =>
    `${BASE_URL}/TopicReport?IdSurvey=${IdSurvey}&Password=${Password}&isLink=true`,
  reportPasswordMojoValidation: (IdSurvey, Password) =>
    `${BASE_URL}/MojoReport?IdSurveyInstance=${IdSurvey}&Password=${Password}&isLink=true`,
  getStixReportCards: IdSurvey => `${BASE_URL}/api/v1/Report/ReportHeader?IdSurvey=${IdSurvey}`,
  getReportsGraphDropDown: IdSurveyType => `${BASE_URL}/api/Graph/GraphDropDown?IdSurveyType=${IdSurveyType}`,
  getReportsSurveyCategoryDropDown: () => `${BASE_URL}/api/Graph/SurveyCategories`,
  getMojoGraph: (IdSurvey, IdDesignation, IntervalType) =>
    `${BASE_URL}/api/Graph/MojoGraphV1?IdSurvey=${IdSurvey}&IdSurveyCategory=${IdDesignation}&IntervalType=${IntervalType}`,
  getStixGraph: (IdSurvey, IdDesignation, IntervalType, IdTopic) =>
    `${BASE_URL}/api/Graph/StixGraphV1?IdSurvey=${IdSurvey}&IdSurveyCategory=${IdDesignation}&IntervalType=${IntervalType}&IdTopic=${IdTopic}`,
};

const ReportService = {
  getOverallReport: async (Idsurvey, IdUserGrpSurvey) => {
    return GET(urlFactory.getOverallReport(Idsurvey, IdUserGrpSurvey), {});
  },
  getDetailedReport: async Idsurvey => {
    return GET(urlFactory.getDetailedReport(), {Idsurvey});
  },
  getSurveyReport: async (searchData, startDate, endDate, workspaceId, sortNewToOld) => {
    return GET(urlFactory.getSurveyReport(searchData, startDate, endDate, workspaceId, sortNewToOld), null);
  },
  getMojoReport: async IdSurveyInstance => {
    return GET(urlFactory.getMojoReport(), {IdSurveyInstance});
  },
  getMojoComments: async (mojoId, commentSize) => {
    const params = {
      IdSurvayInstance: mojoId,
      PageSize: commentSize,
    };
    return GET(urlFactory.getMojoComments(), params);
  },

  getExcelFile: async requestParam => {
    const {Type, id} = requestParam;

    if (Type === 'Mojo') {
      return await GET(urlFactory.getMojoExcelFile(id));
    } else {
      return await GET(urlFactory.getSurveyExcelFile(id));
    }
  },

  shareReport: async requestParam => {
    return await POST(urlFactory.shareReport(), requestParam);
  },

  reportPasswordValidation: async requestParam => {
    const {IdSurvey, Password, IdSurveyType} = requestParam;
    if (IdSurveyType !== 'Mojo') {
      return await GET(urlFactory.reportPasswordValidation(IdSurvey, Password));
    } else {
      return await GET(urlFactory.reportPasswordMojoValidation(IdSurvey, Password));
    }
  },

  getStixReportCards: async idSurvey => {
    return await GET(urlFactory.getStixReportCards(idSurvey));
  },

  getReportsGraphDropDown: async IdSurveyType => {
    return await GET(urlFactory.getReportsGraphDropDown(IdSurveyType));
  },

  getMojoGraph: async requestParams => {
    const {IdSurvey, IdDesignation, IntervalType} = requestParams;
    return await GET(urlFactory.getMojoGraph(IdSurvey, IdDesignation, IntervalType));
  },

  getStixGraph: async requestParams => {
    const {IdSurvey, IdDesignation, IntervalType, IdTopic} = requestParams;
    return await GET(urlFactory.getStixGraph(IdSurvey, IdDesignation, IntervalType, IdTopic));
  },

  getReportsSurveyCategoryDropDown: async () => {
    return await GET(urlFactory.getReportsSurveyCategoryDropDown());
  },
};

export default ReportService;
