import React, {useMemo} from 'react';
import {styled} from '@mui/system';
import {Typography} from '@mui/material';
import {colors} from 'theme';

import Bored from 'assets/images/mojos/bored.svg';
import Chill from 'assets/images/mojos//chill.svg';
import Anxious from 'assets/images/mojos//anxious.svg';
import Overworked from 'assets/images/mojos//overworked.svg';
import Tired from 'assets/images/mojos//tired.svg';
import Blessed from 'assets/images/mojos//blessed.svg';
import Excited from 'assets/images/mojos//excited.svg';
import Angry from 'assets/images/mojos//angry.svg';
import Productive from 'assets/images/mojos/productive.svg';

const mojoImages = [
  {
    id: 2,
    image: Bored,
    // bottom: 16,
    bottom: '28%',
    // left: 16,
    left: '23%',
    top: 'unset',
    right: 'unset',
    width: 32,
    height: 32,
    percentage: 26,
  },
  {
    id: 5,
    image: Chill,
    // top: 14,
    // left: 14,
    top: '26%',
    left: '22%',
    right: 'unset',
    bottom: 'unset',
    width: 32,
    height: 32,
    percentage: 50,
  },
  {
    id: 4,
    image: Tired,
    top: 'unset',
    left: 0,
    right: 'unset',
    // bottom: 4,
    bottom: '4%',
    width: 32,
    height: 32,
  },
  {
    id: 6,
    image: Anxious,
    top: 'unset',
    left: 'unset',
    // right: 14,
    // bottom: 14,
    right: '21%',
    bottom: '25%',
    width: 32,
    height: 32,
  },
  {
    id: 7,
    image: Overworked,
    top: 'unset',
    left: 'unset',
    right: 'unset',
    // bottom: 8,
    bottom: '13%',
    width: 32,
    height: 32,
  },
  {
    id: 8,
    image: Blessed,
    // top: 4,
    top: '4%',
    left: 2,
    right: 'unset',
    bottom: 'unset',
    width: 32,
    height: 32,
  },
  {
    id: 9,
    image: Excited,
    // top: 4,
    top: '4%',
    left: 'unset',
    right: 0,
    bottom: 'unset',
    width: 32,
    height: 32,
  },
  {
    id: 3,
    image: Angry,
    top: 'unset',
    left: 'unset',
    // right: 6,
    // bottom: 4,
    right: '6%',
    bottom: '4%',
    width: 32,
    height: 32,
  },
  {
    id: 1,
    image: Productive,
    // top: 14,
    top: '24%',
    left: 'unset',
    // right: 14,
    right: '19%',
    bottom: 'unset',
    width: 32,
    height: 32,
  },
];
const minSize = 32;
const maxSize = 88;

const MatrixMojoDetailReport = mojoReports => {
  const mojoListWidth = useMemo(() => {
    const temp = mojoImages.map(item => {
      let modified = {
        ...item,
      };
      const findItem = mojoReports.mojoReports.find(it => it.idMojo === item.id);
      if (findItem) {
        // const index = mojoReports.mojoReports.indexOf(findItem);

        const percentageSize = minSize + (maxSize - minSize) * (findItem.mojoPercentage / 100);

        modified.width = Math.min(maxSize, Math.max(minSize, percentageSize));
        modified.height = Math.min(maxSize, Math.max(minSize, percentageSize));
      } else {
        modified.width = 0;
        modified.height = 0;
      }
      return modified;
    });

    return temp;
  }, [mojoReports]);

  return (
    <Root>
      <PositiveTypo variant="subtitle1" color={colors.dark}>
        Positive
      </PositiveTypo>
      <div style={{height: '100%', display: 'contents'}}>
        <HorizontalLine></HorizontalLine>
      </div>
      <NegetiveTypo variant="subtitle1" color={colors.dark}>
        Negative
      </NegetiveTypo>
      {mojoListWidth.map((item, key) => {
        return (
          <TierdImage
            key={key}
            widthValue={item.width}
            heightValue={item.height}
            topValue={item.top}
            bottomValue={item.bottom}
            leftValue={item.left}
            rightValue={item.right}
            src={item.image}
            alt="tired"
          />
        );
      })}

      <div style={{position: 'relative'}}>
        <NonEnergetic variant="subtitle1" color={colors.dark}>
          Non-energetic
        </NonEnergetic>
        <VerticalLine></VerticalLine>
        <Energetic variant="subtitle1" color={colors.dark}>
          Energetic
        </Energetic>
      </div>
    </Root>
  );
};

export default MatrixMojoDetailReport;

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  position: 'relative',
});

const HorizontalLine = styled('div')({
  width: '4px',
  // height: 'calc(70vh - 100px)',
  // minHeight:'calc(70vh - 100px)',
  // height: '90%',
  height: '450px',
  backgroundColor: colors.primary3,
  position: 'absolute',
});

const VerticalLine = styled('div')({
  // width: '65vh',
  width: '450px',
  height: '4px',
  backgroundColor: colors.primary3,
  display: 'flex',
});

const PositiveTypo = styled(Typography)({
  position: 'absolute',
  top: 0,
});

const NegetiveTypo = styled(Typography)({
  position: 'absolute',
  bottom: 0,
});

const NonEnergetic = styled(Typography)({
  position: 'absolute',
  // left: 0,
  top: '-20px',
});

const Energetic = styled(Typography)({
  position: 'absolute',
  right: 0,
  top: '-20px',
});

const TierdImage = styled('img')(({topValue, bottomValue, leftValue, rightValue, widthValue, heightValue}) => ({
  width: `${widthValue}px`,
  height: `${heightValue}px`,
  position: 'absolute',
  bottom: Number(bottomValue) ? `${bottomValue}vh` : bottomValue,
  left: Number(leftValue) ? `${leftValue}vh` : leftValue,
  top: Number(topValue) ? `${topValue}vh` : topValue,
  right: Number(rightValue) ? `${rightValue}vh` : rightValue,
}));
