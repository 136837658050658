import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    ProgressBarStyle: {
      display: 'flex',
      height: 'calc(70vh - 100px)',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '45px 10px 45px',
      gap: '20px',
    },
    progressScale: {
      borderTop: '1px solid',
      borderBottom: '1px solid',
      width: '20px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexFlow: 'column-reverse',
      color: '#9B9B9B',
      position: 'relative',
    },
    label: {
      color: '#9B9B9B',
      fontWeight: '400',
      transform: 'rotate(270deg)',
    },
    smallDevider: {
      borderRight: '1px solid',
      color: '#9B9B9B',
      padding: '5px 0px',
      transform: 'rotate(270deg)',
    },
    devider: {
      borderRight: '1px solid',
      color: '#9B9B9B',
      padding: '10px 0px',
      transform: 'rotate(270deg)',
    },
    whiteBall: {
      background: 'linear-gradient(48.58deg, #8B8B8B 16.67%, #D2D2D2 83.59%)',
      padding: '8px',
      borderRadius: '20px',
    },
    yellowBall: {
      background: 'linear-gradient(56.31deg, #CEBB12 12.67%, #FFE600 90.67%)',
      padding: '8px',
      borderRadius: '20px',
    },
    redBall: {
      background: 'linear-gradient(262.14deg, #D22222 -4.5%, #820505 91.48%)',
      padding: '8px',
      borderRadius: '20px',
    },
  }),
);

export default useStyles;
