import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      // position: 'sticky',
      // top: 0,
      // zIndex: 10,
      backgroundColor: '#fff',
      padding: '24px',
      marginBottom: '10px',
      borderRadius: '10px',
    },
    item: {
      display: 'flex',
      gap: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dot: {
      padding: '10px',
      borderRadius: '20px',
    },
    scaleDesign: {
      display: 'flex',
      borderLeft: '1px solid #222',
      borderRight: '1px solid #222',
      justifyContent: 'space-evenly',
      color: '#9B9B9B',
    },
    scaleLabel: {
      color: '#222',
      fontWeight: '400',
      fontFamily: 'Montserrat !important',
      fontSize: '12px',
      width: '20%',
      display: 'flex',
      justifyContent: 'center',
    },
    scaleDevider: {
      borderRight: '1px solid #222',
      // color: '#9B9B9B',
    },
    textstyle: {
      color: 'var(--dark-900, #222)',
      textAlign: 'center',
      fontSize: '14px',
      fontFamily: 'Montserrat',
      fontWeight: '400',
      lineHeight: '97.4%',
    },
  }),
);

export default useStyles;
