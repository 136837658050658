import {call, put, takeLatest} from 'redux-saga/effects';

import ReportService from 'app/api/report.service';
import {actions as ReportActions} from 'app/store/slices/report.slice';

function* watchGetOverallReport(action) {
  const {Idsurvey, IdUserGrpSurvey} = action.payload;
  try {
    const response = yield call(ReportService.getOverallReport, Idsurvey, IdUserGrpSurvey);
    yield put(ReportActions.getOverallReportSuccess(response));
  } catch (error) {
    yield put(ReportActions.getOverallReportFailure(error));
  }
}

function* watchGetDetailedReport(action) {
  try {
    const response = yield call(ReportService.getDetailedReport, action.payload);
    yield put(ReportActions.getDetailedReportSuccess(response));
  } catch (error) {
    yield put(ReportActions.getDetailedReportFailure(error));
  }
}

function* watchSurveyReport(action) {
  const {searchData, startDate, endDate, workspaceId, sortNewToOld} = action.payload;
  try {
    const response = yield call(
      ReportService.getSurveyReport,
      searchData,
      startDate,
      endDate,
      workspaceId,
      sortNewToOld,
    );
    yield put(ReportActions.getSurveyReportSuccess(response));
  } catch (error) {
    yield put(ReportActions.getSurveyReportFailure(error));
  }
}

function* watchMojoReport(action) {
  try {
    const response = yield call(ReportService.getMojoReport, action.payload);

    yield put(ReportActions.getMojoReportSuccess(response));
  } catch (error) {
    yield put(ReportActions.getMojoReportFailure(error));
  }
}

function* watchMojoComments(action) {
  const {idMojo, commentSize} = action.payload;

  try {
    const response = yield call(ReportService.getMojoComments, idMojo, commentSize);
    yield put(ReportActions.getMojoCommentsSuccess(response));
  } catch (error) {
    yield put(ReportActions.getMojoCommentsFailure(error));
  }
}

function* watchExcelFile(action) {
  try {
    const response = yield call(ReportService.getExcelFile, action.payload);
    yield put(ReportActions.getSurveyExcelFileSuccess(response));
  } catch (error) {
    yield put(ReportActions.getSurveyExcelFileFailure(error));
  }
}

function* watchPostShareReport(action) {
  try {
    const response = yield call(ReportService.shareReport, action.payload);
    yield put(ReportActions.shareReportSuccess(response));
  } catch (error) {
    yield put(ReportActions.shareReportFailure(error));
  }
}

function* watchReportPasswordValidation(action) {
  try {
    yield call(ReportService.reportPasswordValidation, action.payload);
    yield put(ReportActions.reportPasswordValidationSuccess());
  } catch (error) {
    yield put(ReportActions.reportPasswordValidationFailure(error));
  }
}

function* watchGetStixReportCards(action) {
  try {
    const response = yield call(ReportService.getStixReportCards, action.payload);
    yield put(ReportActions.getStixReportCardsSuccess(response));
  } catch (error) {
    yield put(ReportActions.getStixReportCardsFailure(error));
  }
}

function* watchGetReportGraphDropDown(action) {
  try {
    const response = yield call(ReportService.getReportsGraphDropDown, action.payload);
    yield put(ReportActions.getReportGraphDropDownSuccess(response));
  } catch (error) {
    yield put(ReportActions.getReportGraphDropDownFailure(error));
  }
}
function* watchGetReportSurveyCategoryDropDown(action) {
  try {
    const response = yield call(ReportService.getReportsSurveyCategoryDropDown);
    yield put(ReportActions.getReportSurveyCategoryDropDownSuccess(response));
  } catch (error) {
    yield put(ReportActions.getReportSurveyCategoryDropDownFailure(error));
  }
}

function* watchGetMojoGraph(action) {
  try {
    const response = yield call(ReportService.getMojoGraph, action.payload);
    yield put(ReportActions.getMojoGraphSuccess(response));
  } catch (error) {
    yield put(ReportActions.getMojoGraphFailure(error));
  }
}

function* watchGetStixGraph(action) {
  try {
    const response = yield call(ReportService.getStixGraph, action.payload);
    yield put(ReportActions.getStixGraphSuccess(response));
  } catch (error) {
    yield put(ReportActions.getStixGraphFailure(error));
  }
}

const reportSaga = [
  takeLatest(ReportActions.getOverallReport, watchGetOverallReport),
  takeLatest(ReportActions.getDetailedReport, watchGetDetailedReport),
  takeLatest(ReportActions.getSurveyReport, watchSurveyReport),
  takeLatest(ReportActions.getMojoReport, watchMojoReport),
  takeLatest(ReportActions.getMojoComments, watchMojoComments),
  takeLatest(ReportActions.getSurveyExcelFile, watchExcelFile),
  takeLatest(ReportActions.shareReport, watchPostShareReport),
  takeLatest(ReportActions.reportPasswordValidation, watchReportPasswordValidation),
  takeLatest(ReportActions.getStixReportCards, watchGetStixReportCards),
  takeLatest(ReportActions.getReportGraphDropDown, watchGetReportGraphDropDown),
  takeLatest(ReportActions.getReportSurveyCategoryDropDown, watchGetReportSurveyCategoryDropDown),
  takeLatest(ReportActions.getMojoGraph, watchGetMojoGraph),
  takeLatest(ReportActions.getStixGraph, watchGetStixGraph),
];

export default reportSaga;
