import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Typography, Box} from '@mui/material';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';
import {makeStyles} from '@material-ui/core/styles';

import useStyles from './TypeSelectField.Style';
import ErrorIcon from 'assets/images/error.svg';

const useOutlinedInputStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: ' 1px solid #9A9FA5',
        boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08) !important',
      },
      '&:hover fieldset': {
        border: '1px solid var(--grey-500, #E0E3EB)',
        boxShadow: '0px 3px 5px rgba(39, 47, 53, 0.08) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'unset !important',
        borderWidth: '0px !important',
        boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED',
      },
    },
  },
});

export default function TypeSelectField({
  clearButton,
  defaultValue,
  label,
  error,
  surveyTypes,
  selectedValue,
  onChange,
  width,
  height,
  placeholder,
  withBorder,
  fontWeight,
  fontSize,
}) {
  const classes = useStyles();
  const isMobile = useCheckIsMobile();
  const outlinedInputStyles = useOutlinedInputStyles();
  return (
    <>
      <Typography className={classes.textFieldLabel}>{label}</Typography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={surveyTypes}
        className={outlinedInputStyles && error ? classes.errorRoot : classes.root}
        onChange={onChange}
        value={selectedValue}
        disableClearable={clearButton}
        defaultValue={defaultValue}
        placeholder={placeholder}
        sx={
          !isMobile
            ? withBorder === true
              ? {
                  '.MuiOutlinedInput-root': {
                    color: '#fff',
                    height: {height},
                    fontWeight: {fontWeight},
                    fontSize: {fontSize},
                    '& fieldset': {
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 255, 255, 0.15)',
                    },
                    '&:hover fieldset': {
                      border: '1px solid rgba(255, 255, 255, 0.15)',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '0px !important',
                      border: '1px solid rgba(255, 255, 255, 0.15) !important',
                    },
                    '&.MuiAutocomplete-option': {
                      fontSize: '14px',
                    },
                  },
                  '.MuiButtonBase-root': {
                    color: 'unset',
                  },
                }
              : {
                  maxWidth: {width},
                  '.MuiOutlinedInput-root': {
                    height: {height},
                    '& fieldset': {
                      border: '1px solid var(--grey-500, #E0E3EB)',
                      boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08) !important',
                    },
                    '&:hover fieldset': {
                      border: '1px solid var(--grey-500, #E0E3EB)',
                      boxShadow: '0px 3px 5px rgba(39, 47, 53, 0.08) !important',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'unset !important',
                      borderWidth: '0px !important',
                      boxShadow: '0px 0px 0px 2px #DBF2FB, inset 0px 0px 0px 1px #77CBED !important',
                    },
                  },
                  '.MuiAutocomplete-clearIndicator': {
                    opacity: label === 'Langauge' ? '0' : '1',
                  },
                }
            : {
                '.MuiOutlinedInput-root': {
                  color: '#fff',
                  height: {height},
                  fontWeight: {fontWeight},
                  fontSize: {fontSize},
                  '& fieldset': {
                    borderRadius: '4px',
                    border: '1px solid rgba(255, 255, 255, 0.15)',
                  },
                  '&:hover fieldset': {
                    border: '1px solid rgba(255, 255, 255, 0.15)',
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '0px !important',
                    border: '1px solid rgba(255, 255, 255, 0.15) !important',
                  },
                  '&.MuiAutocomplete-option': {
                    fontSize: '14px',
                  },
                },
                '.MuiButtonBase-root': {
                  color: 'unset',
                },
              }
        }
        getOptionLabel={option => option.label}
        renderInput={params => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box style={{fontFamily: 'Montserrat'}} {...props}>
            {option.label}
          </Box>
        )}
      />
      {error && (
        <div className={classes.errorWrapper}>
          <img src={ErrorIcon} alt="error" />
          <Typography className={classes.errorMessage}>{error}</Typography>
        </div>
      )}
    </>
  );
}
