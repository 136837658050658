import {POST, PUT} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  resetPassword: (id, password) => `${BASE_URL}/api/Accounts/ResetPassowrd?id=${id}&newPassword=${password}`,
  setPassword: (username, newPassword) =>
    `${BASE_URL}/api/Accounts/SetPassword?username=${username}&newPassword=${newPassword}`,
};
const resetPasswordService = {
  resetPassword: async (id, password) => {
    return POST(urlFactory.resetPassword(id, password));
  },

  setPassword: async (username, newPassword) => {
    return PUT(urlFactory.setPassword(username, newPassword));
  },
};

export default resetPasswordService;
