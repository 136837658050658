import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import {Slide, Typography} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';

import {actions as generalActions} from 'app/store/slices/general.slice';

import successSVG from 'assets/images/alert/success.svg';
import errorSVG from 'assets/images/alert/error.svg';
import {colors} from 'theme/index';

const colorMap = {
  info: colors.lightDark,
  success: colors.success,
  error: colors.error,
};

const iconMap = {
  info: successSVG,
  success: successSVG,
  error: errorSVG,
};

function TransitionUp(props) {
  return <Slide {...props} direction="left" />;
}

const AppNotification = ({message, info}) => {
  const dispatch = useDispatch();
  const alertData = useSelector(state => state.general.alertData);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(generalActions.setAlertData(null));
  };

  return (
    <Snackbar
      open={alertData}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <SnackbarContent
        style={{
          backgroundColor: alertData && alertData.variant ? colorMap[alertData.variant] : colors.lightDark,
          borderRadius: '16px',
        }}
        message={
          <div style={{display: 'flex'}}>
            <img
              src={alertData && alertData.variant ? iconMap[alertData.variant] : successSVG}
              style={{marginRight: '20px', height: '50px', width: '50px'}}
              alt=""
            />
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Typography
                variant="h6"
                sx={{fontSize: '16px !important', fontWeight: '700 !important', fontFamily: 'Montserrat !important'}}>
                {alertData?.message}
              </Typography>
              <Typography variant="body2" sx={{fontSize: '12px !important', fontFamily: 'Montserrat !important'}}>
                {alertData?.info}
              </Typography>
            </div>
          </div>
        }
      />
    </Snackbar>
  );
};

export default AppNotification;
