import {GET, POST, PUT, DELETE} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getOpenSurveys: (userId, idWorkspaceUser, searchKey) =>
    `${BASE_URL}/api/v1/Users/openStix?idUser=${userId}&IdWorkspaceuser=${idWorkspaceUser}&SearchKey=${searchKey}`,
  getQuestions: () => `${BASE_URL}/api/v1/Survey/questions`,
  addAnswer: () => `${BASE_URL}/Response/Scale`,
  generalInformation: () => `${BASE_URL}/Response/GeneralInformation`,
  getAllUsers: () => `${BASE_URL}/api/v1/user/allUsers`,
  createMojo: () => `${BASE_URL}/api/v1/Survey/CreateMojoSurvey`,
  getUsers: () => `${BASE_URL}/api/v1/Users`,
  validateEmail: (email, idWorkspace) =>
    `${BASE_URL}/api/v1/Users/validateEmail?email=${email}&IdWorkSpace=${idWorkspace}`,
  addUser: () => `${BASE_URL}/api/v1/Users`,
  editUser: () => `${BASE_URL}/api/v1/Users`,
  deleteUser: Id => `${BASE_URL}/api/v1/Users?Id=${Id}`,
  reactivateUser: Id => `${BASE_URL}/api/v1/Users/ReactivateUser?IdWorkspaceUser=${Id}`,
  userAccountSettings: () => `${BASE_URL}/api/v1/Users/UserAccountSettings`,
  addName: () => `${BASE_URL}/api/v1/Users/NormalUserAccoutCreation`,
  getUserRoles: () => `${BASE_URL}/api/v1/Users/UserRoles`,
  getMojoInvitation: IdSurvey => `${BASE_URL}/api/Invitation/MojoInvite/${IdSurvey}`,
  getInvitation: IdWorkspace => `${BASE_URL}/api/Invitation/Link?IdWorkspace=${IdWorkspace}`,
};

const UserService = {
  getOpenSurveys: async (userId, idWorkspaceUser, searchKey) => {
    return GET(urlFactory.getOpenSurveys(userId, idWorkspaceUser, searchKey), null);
  },
  getQuestions: async (idQuestion, IdSurveyType, idWorkspaceUser, idSurveyInstance) => {
    const params = {
      IdQuestion: idQuestion,
      IdSurveyType,
      IdWorkspaceUser: idWorkspaceUser,
      IdSurveyInstances: idSurveyInstance,
    };
    return GET(urlFactory.getQuestions(), params);
  },
  addAnswer: async (data, questionCode) => {
    const url =
      questionCode === 1 || questionCode === 2 || questionCode === 3
        ? urlFactory.generalInformation()
        : urlFactory.addAnswer();
    return POST(url, data);
  },

  getAllUsers: async () => {
    return GET(urlFactory.getAllUsers(), null);
  },
  createMojo: async (userData, mojoName, idWorkspace, idMojo, isUpdate) => {
    return POST(urlFactory.createMojo(), {user: userData, mojoName, idWorkspace, idMojo, isUpdate});
  },
  //need to recheck backend api call from server side.
  getUsers: async (
    IdWorkspace,
    searchKey,
    pagenumber,
    pagesize,
    IdStatus,
    isAscending,
    ColumnName,
    Name,
    Email,
    userGroup,
    UserRoles,
  ) => {
    const params = {
      IdWorkspace,
      searchKey,
      pagesize,
      pagenumber,
      IdStatus,
      isAscending,
      ColumnName,
      Name,
      Email,
      userGroup,
      UserRoles,
    };
    return GET(urlFactory.getUsers(), params);
  },
  validateEmail: async (email, idWorkspace) => {
    return GET(urlFactory.validateEmail(email, idWorkspace), null);
  },
  addUser: async (idWorkspace, email, idRole, group, workspaceName) => {
    return POST(urlFactory.addUser(), {
      idWorkspace,
      email: email,
      Active: true,
      status: 0,
      idRole: idRole,
      userGroupList: group,
      workspaceName,
    });
  },
  editUser: async (idWorkSpaceUser, email, idRole, group, UserId) => {
    return PUT(urlFactory.editUser(), {
      idRole: idRole,
      status: 0,
      email: email,
      idWorkspaceUser: idWorkSpaceUser,
      userGroupList: group,
      idUser: UserId,
    });
  },
  deleteUser: async Id => {
    return DELETE(urlFactory.deleteUser(Id), null, null);
  },
  reactivateUser: async Id => {
    return PUT(urlFactory.reactivateUser(Id), null, null);
  },
  userAccountSettings: async data => {
    return PUT(urlFactory.userAccountSettings(), data);
  },
  addName: async (fullName, idUser, idWorkspaceUser) => {
    return POST(urlFactory.addName(), {fullName: fullName, idUser: idUser, idWorkspaceUser: idWorkspaceUser});
  },
  getUserRoles: async () => {
    return GET(urlFactory.getUserRoles(), null);
  },
  getMojoInvitation: async IdSurvey => {
    return GET(urlFactory.getMojoInvitation(IdSurvey));
  },

  getInvitation: async IdWorkspace => {
    return GET(urlFactory.getInvitation(IdWorkspace));
  },
};

export default UserService;
