import {call, put, takeLatest} from 'redux-saga/effects';

import GroupService from 'app/api/group.service';
import {actions as groupActions} from 'app/store/slices/group.slice';

function* watchGetGroup(action) {
  const {IdWorkspace} = action.payload;
  try {
    const response = yield call(GroupService.getGroup, IdWorkspace);
    yield put(groupActions.getGroupSuccess(response));
  } catch (error) {
    yield put(groupActions.getGroupFailure(error));
  }
}

function* watchUserAndGroup(action) {
  const {IdWorkspace} = action.payload;
  try {
    const response = yield call(GroupService.getUserAndGroup, IdWorkspace);
    yield put(groupActions.userAndGroupSuccess(response));
  } catch (error) {
    yield put(groupActions.userAndGroupFailure(error));
  }
}

function* watchGroupInfo(action) {
  const {Idworkspace, searchKey, isAscending, ColumnName, Name, UserGroup, pageNumber, pagesize} = action.payload;
  try {
    const response = yield call(
      GroupService.getGroupInfo,
      Idworkspace,
      searchKey,
      isAscending,
      ColumnName,
      Name,
      UserGroup,
      pageNumber,
      pagesize,
    );
    yield put(groupActions.groupInfoSuccess(response));
  } catch (error) {
    yield put(groupActions.groupInfoFailure(error));
  }
}

function* watchAddOrEditGroup(action) {
  const {idWorkspace, userGroupName, groupData, idUserGroup} = action.payload;
  try {
    if (idUserGroup) {
      const response = yield call(GroupService.editGroup, idWorkspace, userGroupName, groupData, idUserGroup);
      yield put(groupActions.addGroupSuccess(response));
    } else {
      const response = yield call(GroupService.getAddGroup, idWorkspace, userGroupName, groupData);
      yield put(groupActions.addGroupSuccess(response));
    }
  } catch (error) {
    yield put(groupActions.addGroupFailure(error));
  }
}

function* watchDeleteGroup(action) {
  const {idUserGroup} = action.payload;
  try {
    const response = yield call(GroupService.deleteGroup, idUserGroup);
    yield put(groupActions.deleteGroupSuccess(response));
  } catch (error) {
    yield put(groupActions.deleteGroupFailure(error));
  }
}

function* watchGetAdvancedUserSelect(action) {
  const {IdWorkspace, ColumnName, Name, IdUserGroup, isAscending} = action.payload;
  try {
    const response = yield call(
      GroupService.getAdvancedUserSelect,
      IdWorkspace,
      ColumnName,
      Name,
      IdUserGroup,
      isAscending,
    );
    yield put(groupActions.handleAdvancedUserSelectSuccess(response));
  } catch (error) {
    yield put(groupActions.handleAdvancedUserSelectFailure(error));
  }
}

const reportSaga = [
  takeLatest(groupActions.getGroup, watchGetGroup),
  takeLatest(groupActions.userAndGroup, watchUserAndGroup),
  takeLatest(groupActions.groupInfo, watchGroupInfo),
  takeLatest(groupActions.addGroup, watchAddOrEditGroup),
  takeLatest(groupActions.deleteGroup, watchDeleteGroup),
  takeLatest(groupActions.handleAdvancedUserSelect, watchGetAdvancedUserSelect),
];

export default reportSaga;
