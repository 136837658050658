// src/i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// Import translations
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

// Define resources
const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
