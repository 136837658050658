import {call, put, takeLatest} from 'redux-saga/effects';

import WorkspaceService from 'app/api/workspace.service';
import {actions as workspaceActions} from 'app/store/slices/workspace.slice';
import {actions as authenticationAction} from 'app/store/slices/authentication.slice';

function* watchCreateWorkspace(action) {
  const data = action.payload;
  try {
    const response = yield call(WorkspaceService.createWorkSpace, data);
    yield put(workspaceActions.createWorkSpaceSuccess(response));
  } catch (error) {
    yield put(workspaceActions.createWorkSpaceFailure(error));
  }
}

function* watchGetWorkspace(action) {
  const {IdUser, searchKey, pagenumber, pagesize, isAscending, ColumnName} = action.payload;
  try {
    const response = yield call(
      WorkspaceService.getWorkspace,
      IdUser,
      searchKey,
      pagenumber,
      pagesize,
      isAscending,
      ColumnName,
    );
    const temp = {pagesize, response, pagenumber};
    yield put(workspaceActions.getWorkspaceSuccess(temp));
  } catch (error) {
    yield put(workspaceActions.getWorkspaceFailure(error));
  }
}

function* watchSwapWorkspace(action) {
  const {IdWorkspaceUser, IdUser} = action.payload;
  try {
    const response = yield call(WorkspaceService.swapWorkspace, IdWorkspaceUser, IdUser);
    yield put(workspaceActions.swapWorkspaceSuccess(response));
    yield put(authenticationAction.getUserInfo());
  } catch (error) {
    yield put(workspaceActions.swapWorkspaceError(error));
  }
}

function* watchWorkspaceNameValiadte(action) {
  try {
    const response = yield call(WorkspaceService.workspaceNameValidate, action.payload);
    yield put(workspaceActions.getWorkspaceNameValidateSuccses(response));
  } catch (error) {
    yield put(workspaceActions.getWorkspaceNameValidateFailure(error));
  }
}

function* watchEditWorkspace(action) {
  try {
    yield call(WorkspaceService.editWorkSpace, action.payload);
    yield put(workspaceActions.EditWorkspaceSuccess());
  } catch (error) {
    yield put(workspaceActions.EditWorkspaceFailure(error));
  }
}

function* watchDeleteWorkspace(action) {
  try {
    yield call(WorkspaceService.deleteWorkSpace, action.payload);
    yield put(workspaceActions.DeleteWorkspaceSuccess());
  } catch (error) {
    yield put(workspaceActions.DeleteWorkspaceFailure(error));
  }
}

function* watchLeaveWorkspace(action) {
  const {idWorkSpaceUser, idWorkspace} = action.payload;
  try {
    yield call(WorkspaceService.leaveWorkSpace, idWorkSpaceUser, idWorkspace);
    yield put(workspaceActions.LeaveWorkspaceSuccess());
    // yield put(authenticationAction.logout());
  } catch (error) {
    yield put(workspaceActions.LeaveWorkspaceFailure(error));
  }
}
const workspaceSaga = [
  takeLatest(workspaceActions.createWorkSpace, watchCreateWorkspace),
  takeLatest(workspaceActions.getWorkspace, watchGetWorkspace),
  takeLatest(workspaceActions.swapWorkspace, watchSwapWorkspace),
  takeLatest(workspaceActions.getWorkspaceNameValidateHandler, watchWorkspaceNameValiadte),
  takeLatest(workspaceActions.EditWorkspace, watchEditWorkspace),
  takeLatest(workspaceActions.DeleteWorkspace, watchDeleteWorkspace),
  takeLatest(workspaceActions.LeaveWorkspace, watchLeaveWorkspace),
];

export default workspaceSaga;
