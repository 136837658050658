import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  alertData: null,
  manageYardstixActiveTab: 1,
  allUsers: [],
  allUsersLoading: false,
  allUsersError: null,
  mojoRadioValue: false,
  userActiveTab: 1,
};

const setAlertData = (state, action) => {
  state.alertData = action.payload;
};

const getAllusers = state => {
  state.allUsersLoading = true;
  state.allUsersError = null;
};

const getAllusersSuccess = (state, action) => {
  state.allUsers = action.payload;
  state.allUsersLoading = false;
  state.allUsersError = null;
};

const getAllusersFailure = (state, action) => {
  state.allUsersLoading = false;
  state.allUsersError = action.payload;
};

const setManageYardstixActiveTab = (state, action) => {
  state.manageYardstixActiveTab = action.payload;
};

const setMojoRadioValue = (state, action) => {
  state.mojoRadioValue = action.payload;
};

const setUserActivetab = (state, action) => {
  state.userActiveTab = action.payload;
};

const generalSlice = createSlice({
  name: '@@general',
  initialState,
  reducers: {
    setAlertData(state, action) {
      return setAlertData(state, action);
    },

    getAllusers(state, action) {
      return getAllusers(state, action);
    },
    getAllusersSuccess(state, action) {
      return getAllusersSuccess(state, action);
    },
    getAllusersFailure(state, action) {
      return getAllusersFailure(state, action);
    },
    setManageYardstixActiveTab(state, action) {
      return setManageYardstixActiveTab(state, action);
    },
    setMojoRadioValue(state, action) {
      return setMojoRadioValue(state, action);
    },
    setUserActivetab(state, action) {
      return setUserActivetab(state, action);
    },
  },
});

export const {name, actions, reducer} = generalSlice;
