import {GET, POST, PUT, DELETE} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  getGroup: IdWorkspace => `${BASE_URL}/api/Groups?IdWorkspace=${IdWorkspace}`,
  getUserAndGroup: IdWorkspace => `${BASE_URL}/api/Groups/UsersAndGroups?IdWorkspace=${IdWorkspace}`,
  getGroupInfo: (Idworkspace, searchKey, isAscending, ColumnName, Name, UserGroup, pageNumber, pagesize) =>
    `${BASE_URL}/api/Groups/GroupsInfo?Idworkspace=${Idworkspace}&searchKey=${searchKey}&isAscending=${isAscending}&ColumnName=${ColumnName}&Name=${Name}&UserGroup=${UserGroup}&pagenumber=${pageNumber}&pagesize=${pagesize}`,
  getAddGroup: () => `${BASE_URL}/api/Groups`,
  deleteGroup: idUserGroup => `${BASE_URL}/api/Groups?IduserGroup=${idUserGroup}`,
  editGroup: (idWorkspace, idUserGroup) =>
    `${BASE_URL}/api/Groups?IdUserGroup=${idUserGroup}&IdWorkspace=${idWorkspace}`,
  getAdvancedUserSelect: (IdWorkspace, ColumnName, Name, IdUserGroup, isAscending) =>
    `${BASE_URL}/api/Groups/GetAdvanceUserSelect?IdWorkspace=${IdWorkspace}&ColumnName=${ColumnName}&Name=${Name}&IdUserGroup=${IdUserGroup}&isAscending=${isAscending}`,
};

const GroupService = {
  getGroup: async IdWorkspace => {
    return GET(urlFactory.getGroup(IdWorkspace), null);
  },
  deleteGroup: async idUserGroup => {
    return DELETE(urlFactory.deleteGroup(idUserGroup), null);
  },
  getGroupInfo: async (Idworkspace, searchKey, isAscending, ColumnName, Name, UserGroup, pageNumber, pagesize) => {
    return GET(
      urlFactory.getGroupInfo(Idworkspace, searchKey, isAscending, ColumnName, Name, UserGroup, pageNumber, pagesize),
      null,
    );
  },
  getAddGroup: async (idWorkspace, userGroupName, groupData) => {
    return POST(urlFactory.getAddGroup(), {
      idWorkspace,
      userGroupName: userGroupName,
      users: groupData,
    });
  },
  getUserAndGroup: async IdWorkspace => {
    return GET(urlFactory.getUserAndGroup(IdWorkspace), null);
  },

  editGroup: async (idWorkspace, userGroupName, groupData, idUserGroup) => {
    return PUT(urlFactory.editGroup(idWorkspace, idUserGroup), {
      idWorkspace,
      userGroupName: userGroupName,
      users: groupData,
    });
  },

  getAdvancedUserSelect: async (IdWorkspace, ColumnName, Name, IdUserGroup, isAscending) => {
    return GET(urlFactory.getAdvancedUserSelect(IdWorkspace, ColumnName, Name, IdUserGroup, isAscending), null);
  },
};

export default GroupService;
