import React from 'react';
import {Typography, TextField} from '@material-ui/core';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

import moment from 'moment';

import useStyles from './DatePicker.styles';
import ErrorIcon from 'assets/images/error.svg';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const AppDatePicker = ({label, error, minDate, onDateChange, preDate, style, disabled}) => {
  const classes = useStyles();
  const handleDateChange = newValue => {
    onDateChange(moment(new Date(newValue)).format());
  };

  return (
    <div>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            className={!error ? classes.picker : classes.pickerError}
            InputProps={{
              disableUnderline: true,
            }}
            OpenPickerButtonProps={{
              sx: {
                color: '#000 !important',
              },
            }}
            format="DD-MM-YYYY"
            value={dayjs(preDate)}
            minDate={minDate}
            disablePast={preDate ? false : true}
            onChange={handleDateChange}
            renderInput={params => <TextField size="small" {...params} />}
            disabled={disabled}
            // style={style}
            sx={{
              '& .Mui-disabled': {
                color: 'grey !important',
                WebkitTextFillColor: 'grey !important',
                opacity: '0.5 !important',
              },
              '& .MuiOutlinedInput-input': {
                padding: '6.5px 10px',
              },
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
      {error && (
        <div className={classes.errorWrapper}>
          <img src={ErrorIcon} alt="error" />
          <Typography className={classes.errorMessage}>{error}</Typography>
        </div>
      )}
    </div>
  );
};

export default AppDatePicker;
