import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
// import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

import Reactotron from 'config/ReactotronConfig';

import {reducer as authenticationReducer} from 'app/store/slices/authentication.slice';
import {reducer as contactAndFeedbackReducer} from 'app/store/slices/contactAndFeedback.slice';
import {reducer as ForgotPasswordReducer} from 'app/store/slices/forgotPassword.slice';
import {reducer as generalReducer} from 'app/store/slices/general.slice';
import {reducer as GroupReducer} from 'app/store/slices/group.slice';
import {reducer as projectPhasesReducer} from 'app/store/slices/projectPhases.slice';
import {reducer as reportReducer} from 'app/store/slices/report.slice';
import {reducer as resetPasswordReducer} from 'app/store/slices/resetPassword.slice';
import {reducer as surveyReducer} from 'app/store/slices/survey.slice';
import {reducer as userReducer} from 'app/store/slices/user.slice';
import {reducer as workspaceReducer} from 'app/store/slices/workspace.slice';
import rootSaga from './sagas';

//initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

//Push all the middlewares to below array
const middlewares = [sagaMiddleware, thunk];

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(createLogger());
// }

const reducers = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  survey: surveyReducer,
  report: reportReducer,
  contactAndFeedback: contactAndFeedbackReducer,
  general: generalReducer,
  workspace: workspaceReducer,
  group: GroupReducer,
  ForgotPassword: ForgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  projectPhases: projectPhasesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: middlewares,
  enhancers: [Reactotron.createEnhancer()],
});

export const persistor = persistStore(store);

//run saga middleware
sagaMiddleware.run(rootSaga);
