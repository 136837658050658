import {useSelector} from 'react-redux';
import {Outlet, Navigate} from 'react-router-dom';

import AnonymousLayout from 'app/Layout/AnonymousLayout';

const AnonymousRoutes = () => {
  const ValidationResponse = useSelector(state => state.authentication.postAnonymousValidationData);
  return (
    <>
      {ValidationResponse === 'Valid Guild-Id' ? (
        <>
          <AnonymousLayout />
          <Outlet />
        </>
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default AnonymousRoutes;
