import React from 'react';

// import {AppSnackBar} from 'app/shared/components';
import MainRoutes from 'app/routes/MainRoute';
import {AppNotification} from 'app/shared/components';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';

import './App.css';

const App = () => {
  const isMobile = useCheckIsMobile();
  return (
    <div style={isMobile ? {background: ''} : {height: '100vh', background: '#FFF'}}>
      <MainRoutes />
      <AppNotification />
    </div>
  );
};

export default App;
