import {call, put, takeLatest} from 'redux-saga/effects';

import SurveyService from 'app/api/servey.service';
import {actions as surveyActions} from 'app/store/slices/survey.slice';

function* watchGetSurveys(action) {
  const {IdWorkspace, page: pagenumber, pageSize: pagesize, ColumnName, isAscending, ...bodyParams} = action.payload;
  try {
    const response = yield call(
      SurveyService.getSurveys,
      IdWorkspace,
      pagenumber,
      pagesize,
      ColumnName,
      isAscending,
      bodyParams,
    );
    yield put(surveyActions.getSurveysSuccess({response, page: pagenumber}));
  } catch (error) {
    yield put(surveyActions.getSurveysFailure(error));
  }
}

function* watchGetSurveyTypes(action) {
  try {
    const response = yield call(SurveyService.getSurveyTypes);
    yield put(surveyActions.getSurveyTypesSuccess(response));
  } catch (error) {
    yield put(surveyActions.getSurveyTypesFailure(error));
  }
}

function* watchAddSurvey(action) {
  const data = action.payload;
  try {
    const response = yield call(SurveyService.addSurvey, data);
    yield put(surveyActions.addSurveySuccess(response));
  } catch (error) {
    yield put(surveyActions.addSurveyFailure(error));
  }
}

function* watchEditSurvey(action) {
  const data = action.payload.dataToUpload;
  const Id = action.payload.Id;
  const idSurvey = action.payload.idSurvey;
  try {
    const response = yield call(SurveyService.editSurvey, data, Id, idSurvey);
    yield put(surveyActions.editSurveySuccess(response));
  } catch (error) {
    yield put(surveyActions.editSurveyFailure(error));
  }
}

function* watchDeleteSurvey(action) {
  const Id = action.payload.IdUserGroupSurvey;
  try {
    const response = yield call(SurveyService.deleteSurvey, Id);
    yield put(surveyActions.deleteSurveySuccess(response));
  } catch (error) {
    yield put(surveyActions.deleteSurveyFailure(error));
  }
}

function* watchStatusTypes(action) {
  try {
    const response = yield call(SurveyService.getStatusTypes);
    yield put(surveyActions.statusTypesSuccess(response));
  } catch (error) {
    yield put(surveyActions.statusTypesFailure(error));
  }
}

function* watchGetParticipants(action) {
  const {IdWorkspace, IdUserGroupSurvey, pagesize, pagenumber, ...bodyParams} = action.payload;

  try {
    const response = yield call(
      SurveyService.getParticiPant,
      IdWorkspace,
      IdUserGroupSurvey,
      pagenumber,
      pagesize,
      bodyParams,
    );
    yield put(surveyActions.getParticiPantSuccess(response));
  } catch (error) {
    yield put(surveyActions.getParticipantFailure(error));
  }
}

const surveySaga = [
  takeLatest(surveyActions.getSurveys, watchGetSurveys),
  takeLatest(surveyActions.getSurveyTypes, watchGetSurveyTypes),
  takeLatest(surveyActions.addSurvey, watchAddSurvey),
  takeLatest(surveyActions.editSurvey, watchEditSurvey),
  takeLatest(surveyActions.deleteSurvey, watchDeleteSurvey),
  takeLatest(surveyActions.statusTypes, watchStatusTypes),
  takeLatest(surveyActions.getParticipant, watchGetParticipants),
];

export default surveySaga;
