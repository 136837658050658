export const RandomColors = designationNames => {
  // const generateRandomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

  // return designationNames.reduce((obj, xvalue) => {
  //   obj[xvalue] = generateRandomColor();
  //   return obj;
  // }, {});

  const fixedColors = [
    '#FF69B4', // Pink
    '#82ca9d', // Green
    '#C00707', // Red
    '#7D3C98', // Purple
    '#F39C12', // Dark Yellow
    '#2C3E50', // Dark Blue
    '#000000', // black
    // Add more colors if needed
  ];

  return designationNames.reduce((obj, xvalue, index) => {
    // Assign a color from the fixed colors array based on index
    obj[xvalue] = fixedColors[index];
    return obj;
  }, {});
};
