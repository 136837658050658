import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: '#fafafa',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    dot: {
      borderRadius: '100%',
      padding: '10px',
    },
    label: {
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '500',
    },
    value: {
      display: 'flex',
      fontFamily: 'Montserrat',
      fontSize: '22px',
      fontWeight: '500',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
