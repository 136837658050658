import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  surveys: [],
  surveyCount: null,
  surveysLoading: false,
  surveysPage: 1,
  surveysError: null,
  surveyTotalPage: 0,

  surveyTypes: [],
  surveyTypesLoading: false,
  surveyTypesError: null,

  addSurveyResponse: null,
  addSurveyloading: false,
  addSurveyError: null,

  editSurveyResponse: null,
  editSurveyloading: false,
  editSurveyError: null,

  deleteSurveyLoading: false,
  deleteSurveyError: null,

  selectedSurveyForUpdate: null,

  statusTypes: null,
  statusTypesLoading: false,
  statusTypesError: null,

  getParticipants: null,
  getParticiPantsLoading: false,
  getParticipantsError: null,
};

const getSurveys = (state, action) => {
  const {page} = action.payload;
  if (page === 1) {
    state.surveys = [];
  }
  state.surveysPage = page;
  state.surveysLoading = true;
  state.surveysError = null;
};

const getSurveysSuccess = (state, action) => {
  const {response, page} = action.payload;
  const count = response[0].count;
  const totalPage = response[0].totalPages;

  state.isSurvey = response[0]?.surveyDetailsModels;
  state.surveys =
    page === 1 ? response[0]?.surveyDetailsModels : [...state.surveys, ...response[0].surveyDetailsModels];
  state.surveyCount = count;
  state.surveyTotalPage = totalPage;
  state.surveysLoading = false;
};

const getSurveysFailure = (state, action) => {
  state.surveysError = action.payload;
  state.surveysLoading = false;
};

const getSurveyTypes = (state, action) => {
  state.surveyTypesLoading = true;
  state.surveyTypesError = null;
};

const getSurveyTypesSuccess = (state, action) => {
  const data = action.payload?.map(item => ({
    label: item.surveyTypeName,
    value: item.idSurveyType,
  }));
  state.surveyTypes = data;
  state.surveyTypesLoading = false;
  state.surveyTypesError = null;
};

const getSurveyTypesFailure = (state, action) => {
  state.surveyTypesLoading = false;
  state.surveyTypesError = action.payload;
};

const setSelectedSurveyForUpdate = (state, action) => {
  state.selectedSurveyForUpdate = action.payload;
};

const addSurvey = (state, action) => {
  state.addSurveyloading = true;
  state.addSurveyError = null;
};

const addSurveySuccess = (state, action) => {
  state.addSurveyloading = false;
  state.addSurveyResponse = action.payload;
  state.addSurveyError = null;
};

const addSurveyFailure = (state, action) => {
  state.addSurveyloading = false;
  state.addSurveyError = action.payload;
};

const editSurvey = (state, action) => {
  state.editSurveyloading = true;
  state.editSurveyError = null;
};

const editSurveySuccess = (state, action) => {
  state.editSurveyloading = false;
  state.editSurveyResponse = action.payload;
  state.editSurveyError = null;
};

const editSurveyFailure = (state, action) => {
  state.editSurveyloading = false;
  state.editSurveyError = action.payload;
};

const deleteSurvey = (state, action) => {
  state.deleteSurveyLoading = true;
  state.deleteSurveyError = null;
};

const deleteSurveySuccess = (state, action) => {
  state.deleteSurveyLoading = false;
  state.deleteSurveyError = null;
};

const deleteSurveyFailure = (state, action) => {
  state.deleteSurveyLoading = false;
  state.deleteSurveyError = action.payload;
};

const statusTypes = (state, action) => {
  state.statusTypesLoading = true;
  state.statusTypesError = null;
};

const statusTypesSuccess = (state, action) => {
  state.statusTypesLoading = false;
  state.statusTypes = action.payload;
  state.statusTypesError = null;
};

const statusTypesFailure = (state, action) => {
  state.statusTypesLoading = false;
  state.statusTypesError = action.payload;
};

const getParticipant = (state, action) => {
  state.getParticiPantsLoading = true;
  state.getParticipantsError = null;
};

const getParticiPantSuccess = (state, action) => {
  state.getParticiPantsLoading = false;
  state.participants = action.payload;
  state.getParticipantsError = null;
};

const getParticipantFailure = (state, action) => {
  state.getParticiPantsLoading = false;
  state.getParticipantsError = action.payload;
};

const surveySlice = createSlice({
  name: '@@survey',
  initialState,
  reducers: {
    getSurveys(state, action) {
      return getSurveys(state, action);
    },
    getSurveysSuccess(state, action) {
      return getSurveysSuccess(state, action);
    },
    getSurveysFailure(state, action) {
      return getSurveysFailure(state, action);
    },
    getSurveyTypes(state, action) {
      return getSurveyTypes(state, action);
    },
    getSurveyTypesSuccess(state, action) {
      return getSurveyTypesSuccess(state, action);
    },
    getSurveyTypesFailure(state, action) {
      return getSurveyTypesFailure(state, action);
    },
    setSelectedSurveyForUpdate(state, action) {
      return setSelectedSurveyForUpdate(state, action);
    },
    addSurvey(state, action) {
      return addSurvey(state, action);
    },
    addSurveySuccess(state, action) {
      return addSurveySuccess(state, action);
    },
    addSurveyFailure(state, action) {
      return addSurveyFailure(state, action);
    },
    editSurvey(state, action) {
      return editSurvey(state, action);
    },
    editSurveySuccess(state, action) {
      return editSurveySuccess(state, action);
    },
    editSurveyFailure(state, action) {
      return editSurveyFailure(state, action);
    },
    deleteSurvey(state, action) {
      return deleteSurvey(state, action);
    },
    deleteSurveySuccess(state, action) {
      return deleteSurveySuccess(state, action);
    },
    deleteSurveyFailure(state, action) {
      return deleteSurveyFailure(state, action);
    },
    statusTypes(state, action) {
      return statusTypes(state, action);
    },
    statusTypesSuccess(state, action) {
      return statusTypesSuccess(state, action);
    },
    statusTypesFailure(state, action) {
      return statusTypesFailure(state, action);
    },
    getParticipant(state, action) {
      return getParticipant(state, action);
    },
    getParticiPantSuccess(state, action) {
      return getParticiPantSuccess(state, action);
    },
    getParticipantFailure(state, action) {
      return getParticipantFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = surveySlice;
