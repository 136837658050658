import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  groupData: [],
  getGroupLoading: false,
  getGroupError: null,

  userAndGroupData: null,
  userAndGroupLoading: false,
  userAndGroupError: null,

  groupInfoData: null,
  groupInfoLoading: false,
  groupInfoError: null,

  updateGroup: null,

  deleteGroupLoading: false,
  deleteGroupError: null,

  addGroup: null,
  addGroupLoading: false,
  addGroupError: null,

  getAdvancedUserSelect: null,
  getAdvancedUserSelectLoading: false,
  getAdvancedUserSelectError: null,
};

const getGroup = (state, action) => {
  state.getGroupLoading = true;
  state.getGroupError = null;
};

const getGroupSuccess = (state, action) => {
  state.getGroupLoading = false;
  state.groupData = action.payload;
  state.getGroupError = null;
};

const getGroupFailure = (state, action) => {
  state.getGroupLoading = false;
  state.getGroupError = action.payload;
};

const userAndGroup = (state, action) => {
  state.userAndGroupLoading = true;
  state.userAndGroupError = null;
};

const userAndGroupSuccess = (state, action) => {
  state.userAndGroupLoading = false;
  state.userAndGroupData = action.payload;
  state.userAndGroupError = null;
};

const userAndGroupFailure = (state, action) => {
  state.userAndGroupLoading = false;
  state.userAndGroupError = action.payload;
};

const groupInfo = (state, action) => {
  state.groupInfoLoading = true;
  state.groupInfoError = null;
};

const groupInfoSuccess = (state, action) => {
  state.groupInfoLoading = false;
  state.groupInfoData = action.payload;
  state.groupInfoError = null;
};

const groupInfoFailure = (state, action) => {
  state.groupInfoLoading = false;
  state.userAndGroupError = action.payload;
};

const setUpdateGroup = (state, action) => {
  state.updateGroup = action.payload;
};

const deleteGroup = (state, action) => {
  state.deleteGroupLoading = true;
  state.deleteGroupError = null;
};

const deleteGroupSuccess = (state, action) => {
  state.deleteGroupLoading = false;
  state.deleteGroupError = null;
};

const deleteGroupFailure = (state, action) => {
  state.deleteGroupLoading = false;
  state.deleteGroupError = action.payload;
};

const addGroup = (state, action) => {
  state.addGroupLoading = true;
  state.addGroupError = null;
};

const addGroupSuccess = (state, action) => {
  state.addGroupLoading = false;
  state.addGroupData = action.payload;
  state.addGroupError = null;
};

const addGroupFailure = (state, action) => {
  state.addGroupLoading = false;
  state.addGroupError = action.payload;
};

const handleAdvancedUserSelect = (state, action) => {
  state.getAdvancedUserSelectLoading = true;
  state.getAdvancedUserSelectError = null;
};

const handleAdvancedUserSelectSuccess = (state, action) => {
  state.getAdvancedUserSelectLoading = false;
  state.getAdvancedUserSelect = action.payload;
  state.getAdvancedUserSelectError = null;
};

const handleAdvancedUserSelectFailure = (state, action) => {
  state.getAdvancedUserSelectLoading = false;
  state.getAdvancedUserSelectError = action.payload;
};

const groupSlice = createSlice({
  name: '@@report',
  initialState,
  reducers: {
    getGroup(state, action) {
      return getGroup(state, action);
    },
    getGroupSuccess(state, action) {
      return getGroupSuccess(state, action);
    },
    getGroupFailure(state, action) {
      return getGroupFailure(state, action);
    },
    userAndGroup(state, action) {
      return userAndGroup(state, action);
    },
    userAndGroupSuccess(state, action) {
      return userAndGroupSuccess(state, action);
    },
    userAndGroupFailure(state, action) {
      return userAndGroupFailure(state, action);
    },
    groupInfo(state, action) {
      return groupInfo(state, action);
    },
    groupInfoSuccess(state, action) {
      return groupInfoSuccess(state, action);
    },
    groupInfoFailure(state, action) {
      return groupInfoFailure(state, action);
    },
    setUpdateGroup(state, action) {
      return setUpdateGroup(state, action);
    },
    deleteGroup(state, action) {
      return deleteGroup(state, action);
    },
    deleteGroupSuccess(state, action) {
      return deleteGroupSuccess(state, action);
    },
    deleteGroupFailure(state, action) {
      return deleteGroupFailure(state, action);
    },
    addGroup(state, action) {
      return addGroup(state, action);
    },
    addGroupSuccess(state, action) {
      return addGroupSuccess(state, action);
    },
    addGroupFailure(state, action) {
      return addGroupFailure(state, action);
    },

    handleAdvancedUserSelect(state, action) {
      return handleAdvancedUserSelect(state, action);
    },
    handleAdvancedUserSelectSuccess(state, action) {
      return handleAdvancedUserSelectSuccess(state, action);
    },
    handleAdvancedUserSelectFailure(state, action) {
      return handleAdvancedUserSelectFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = groupSlice;
