import {call, put, takeLatest} from 'redux-saga/effects';
import resetPasswordService from 'app/api/resetPassword.service';
import {actions as resetPasswordActions} from 'app/store/slices/resetPassword.slice';

function* watchHandleResetPassword(action) {
  const {id, password} = action.payload;
  try {
    yield call(resetPasswordService.resetPassword, id, password);
    yield put(resetPasswordActions.postResetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordActions.postResetPasswordFailure(error));
  }
}

function* watchHandleSetPassword(action) {
  const {username, newPassword} = action.payload;
  try {
    yield call(resetPasswordService.setPassword, username, newPassword);
    yield put(resetPasswordActions.postSetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordActions.postSetPasswordFailure(error));
  }
}

const resetPasswordSaga = [
  takeLatest(resetPasswordActions.postResetPassword, watchHandleResetPassword),
  takeLatest(resetPasswordActions.postSetPassword, watchHandleSetPassword),
];
export default resetPasswordSaga;
