import React from 'react';
import {Typography, Grid} from '@material-ui/core';

import useStyles from './DetailReportHeader.styles';
import useCheckIsMobile from 'app/utils/useCheckIsMobile';

const HeaderLabels = [
  {
    label: 'Start',
    background: 'linear-gradient(48.58deg, #8B8B8B 16.67%, #D2D2D2 83.59%)',
  },
  {
    label: 'Current',
    background: 'linear-gradient(56.31deg, #CEBB12 12.67%, #FFE600 90.67%)',
  },
  // {
  //   label: 'Target',
  //   background: 'linear-gradient(262.14deg, #D22222 -4.5%, #820505 91.48%)',
  // },
];

const ScaleItems = ['BASIC ', 'MEDIUM', 'GOOD  ', 'ELITE'];

const DetailReportHeader = () => {
  const classes = useStyles();
  const isMobile = useCheckIsMobile();
  return (
    <div className={classes.root} style={!isMobile ? {width: '350px'} : {}}>
      <Grid item xs={12} style={{display: 'flex', paddingBottom: 26, justifyContent: 'center'}}>
        {HeaderLabels.map((item, index) => (
          <Grid item xs={4} className={classes.item} key={index}>
            <div className={classes.dot} style={{background: item.background, fontFamily: 'Montserrat'}} />
            <Typography className={classes.textstyle}>{item.label}</Typography>
          </Grid>
        ))}
      </Grid>
      <div className={classes.scaleDesign}>
        {ScaleItems.map((item, index) => (
          <React.Fragment key={index}>
            <Typography className={classes.scaleLabel}>{item}</Typography>
            {index !== ScaleItems.length - 1 && <div className={classes.scaleDevider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default DetailReportHeader;
