import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sendFeedbackLoading: false,
  sendFeedbackResp: null,
  sendFeedbackError: null,

  sendContactResp: null,
  sendContactLoading: false,
  sendContactError: null,
};

const sendFeedback = state => {
  state.sendFeedbackLoading = true;
  state.sendFeedbackResp = null;
  state.sendFeedbackError = null;
};

const sendFeedbackuccess = (state, action) => {
  state.sendFeedbackLoading = false;
  state.sendFeedbackResp = action.payload;
  state.sendFeedbackError = null;
};

const sendFeedbackFailure = (state, action) => {
  state.sendFeedbackLoading = false;
  state.sendFeedbackResp = null;
  state.sendFeedbackError = action.payload;
};

const sendContact = state => {
  state.sendContactLoading = true;
  state.sendContactError = null;
};

const sendContactSuccess = (state, action) => {
  state.sendContactResp = action.payload;
  state.sendContactLoading = false;
  state.sendContactError = null;
};

const sendContactFailure = (state, action) => {
  state.sendContactLoading = false;
  state.sendContactError = action.payload;
};

const contactAndFeedbackSlice = createSlice({
  name: '@@contactAndFeedback',
  initialState,
  reducers: {
    sendFeedback(state, action) {
      return sendFeedback(state, action);
    },
    sendFeedbackuccess(state, action) {
      return sendFeedbackuccess(state, action);
    },
    sendFeedbackFailure(state, action) {
      return sendFeedbackFailure(state, action);
    },
    sendContact(state, action) {
      return sendContact(state, action);
    },
    sendContactSuccess(state, action) {
      return sendContactSuccess(state, action);
    },
    sendContactFailure(state, action) {
      return sendContactFailure(state, action);
    },
  },
});

export const {name, actions, reducer} = contactAndFeedbackSlice;
