import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  postPasswordRecoveryEmailLoading: false,
  PasswordRecoveryEmailError: null,
};
const ForgotPasswordSlice = createSlice({
  name: '@@forgotPassword',
  initialState,
  reducers: {
    postPasswordRecoveryEmail(state, action) {
      state.postPasswordRecoveryEmailLoading = true;
    },
    postPasswordRecoveryEmailSuccess(state) {
      state.postPasswordRecoveryEmailLoading = false;
    },
    postPasswordRecoveryEmailFailure(state, action) {
      state.postPasswordRecoveryEmailLoading = false;
      state.PasswordRecoveryEmailError = action.payload;
    },
  },
});

export const {name, actions, reducer} = ForgotPasswordSlice;
