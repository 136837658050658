import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 15,
    },
    iconButton: {
      // backgroundColor: 'rgb(231,61,61) !important',
      color: '#FFF !important',
      padding: '5px 15px !important',
      fontSize: '12 !important',
      textTransform: 'none !important',
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    buttonSpan: {
      marginTop: 2,
      fontFamily: 'Montserrat',
    },
    buttonIcon: {
      width: 16,
      height: 16,
    },
  }),
);

export default useStyles;
