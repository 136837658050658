import {POST} from './api.service';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const urlFactory = {
  sendFeedback: () => `${BASE_URL}/api/v1/Survey/FeedbackEmail`,
  sendContact: () => `${BASE_URL}/api/v1/Survey/ContactPageEmail`,
};

const ContactAndFeedbackService = {
  sendFeedback: async (comment, name, email) => {
    return POST(urlFactory.sendFeedback(), {
      userEmail: email,
      name: name,
      EmailBody: comment,
    });
  },
  sendContact: async (email, company, message) => {
    return POST(urlFactory.sendContact(), {
      userEmail: email,
      emailBody: message,
      name: '',
      companyName: company,
      emailAddress: email,
    });
  },
};

export default ContactAndFeedbackService;
