import {createStyles, makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    dashboardRoot: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      fontFamily: 'Montserrat',
      backgroundColor: '#fafafa',
    },
  }),
);

export default useStyles;
